import moment from "moment";
import months from "../data/months";

export default {
	single(data) {
		data["original_created_at"] = data["created_at"];
		data["created_at"] = moment(data["created_at"]).format("DD/MM/YYYY");
		return data;
	},
	multiline(data, hours = false) {
		return data.map((item) => {
			item["original_created_at"] = item["created_at"];
			if (hours) {
				item["created_at"] = moment(item["created_at"]).format(
					"DD/MM/YYYY HH:mm"
				);
			} else {
				item["created_at"] = moment(item["created_at"]).format("DD/MM/YYYY");
			}
			return item;
		});
	},
	field(data) {
		return moment(data).format("DD/MM/YYYY");
	},
	fields(data, fields) {
		return data.map((item) => {
			fields.forEach((field) => {
				item[field] = moment(item[field]).format("DD/MM/YYYY");
			});
			return item;
		});
	},
	fieldsWithTime(data, fields) {
		return data.map((item) => {
			fields.forEach((field) => {
				item[field] = moment(item[field]).format("DD/MM/YYYY HH:mm");
			});
			return item;
		});
	},
	fromDB(date, hours = true) {
		if (date == "0000-00-00 00:00:00" || date == null) {
			return "";
		}

		if (hours) {
			return moment(date).format("DD/MM/YYYY HH:mm");
		}

		return moment(date).format("DD/MM/YYYY");
	},
	monthNumberToString(number) {
		let language = localStorage.getItem("currentLang");
		var month;
		try {
			if (typeof number != "string") {
				number = number < 10 ? "0" + number : number.toString();
			}
			switch (number) {
				case "01":
					month = months[language][number];
					break;
				case "02":
					month = months[language][number];
					break;
				case "03":
					month = months[language][number];
					break;
				case "04":
					month = months[language][number];
					break;
				case "05":
					month = months[language][number];
					break;
				case "06":
					month = months[language][number];
					break;
				case "07":
					month = months[language][number];
					break;
				case "08":
					month = months[language][number];
					break;
				case "09":
					month = months[language][number];
					break;
				case "10":
					month = months[language][number];
					break;
				case "11":
					month = months[language][number];
					break;
				case "12":
					month = months[language][number];
					break;
				default:
					month = false;
			}
		} catch (e) {
			month = number;
		}

		return month;
	},
	lastMonthDate() {
		return moment().subtract(1, "month").toISOString().substr(0, 7);
	},
};
