export default {
	namespaced: true,
	state: () => ({
		list: [],
		view: {
			word: {},
			term: {},
			file: null,
			headers: {
				terms: null,
				words: null,
				verticals: null,
			},
		},
	}),
	mutations: {
		resetView(state) {
			state.view = {
				word: {},
				term: {},
				file: null,
				headers: {
					terms: null,
					words: null,
					verticals: null,
				},
			};
		},
	},
};
