export default [
	{
		path: '/approvals',
		name: 'approval-list',
		component: function () {
			return import(/* webpackChunkName: "approval-list" */ '../views/approvals/List.vue')
		}
	},
].map( item => {
	item.meta = { tab: 'approvals' }
	return item
})