<template>
    <div v-if="showDiv" class="showCountAlert">
        <div v-html="$t('alerts.pendency', { total: alertsCount})" class="contentA" @click="openNotificationPanel" />
        <div class="contentB" v-show="details.length">
            <ul>
                <li v-for="(item, index) in details"
                    :key="index">
                    <strong>{{getErrorMessage(item.code)}}</strong> {{item.message}}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    data: () => ({
        showDivB: true,
        details: []
    }),
    computed: {
        alertsCount() {
            return this.$store.state.alert.list && this.$store.state.alert.list.length
        },
        showDiv(){
            return ( this.alertsCount && this.$route.name != 'notifications' )
        }
    },
    watch: {
        '$route.name': function( value ){
            this.details = []
            if( value == 'order-view' ){
                this.loadingDetails()
            }
        }
    },
    created(){
        this.get()
    },
    methods: {
        async get(){
            if (this.$store.state.auth.isLogged) {
                await this.$store.dispatch( 'alert/load' )
            }
        },
        openNotificationPanel(){
            this.$router.push({
                name: 'notifications'
            })
        },
        async loadingDetails(){
            const result = await this.$store.dispatch( 'alert/getDetails', this.$route.params.id )
            this.details = result
        },
        getErrorMessage( code ){
            var message
            switch (code) {
                case '01':
                    message = 'Erro nos dados do cabeçalho'
                    break;

                case '02':
                    message = 'Erro nos dados dos produtos'
                    break;

                case '03':
                    message = 'Erro nos valores dos produtos'
                    break;

                case '04':
                    message = 'Erro nos valores de descontos'
                    break;

                case '05':
                    message = 'Erro no valor total do pedido'
                    break;

                default:
                    break;
            }

            return message
        }
    }
}
</script>

<style lang="scss" scoped>
.showCountAlert {
    padding: 30px 0;

    .contentA {
        background-color: #be0000;
        color: #fff;
        padding: 10px 20px;
        border-radius: 5px;
        cursor: pointer;
    }

    .contentB {
        margin-top: 30px;
        background-color: #be5c00;
        color: #fff;
        padding: 10px 20px;
        border-radius: 5px;
    }
}
</style>
