export default [
	{
		path: '/orders/preview',
		name: 'order-preview',
		component: function () {
			return import(/* webpackChunkName: "order-preview" */ '../views/orders/Preview.vue')
		}
	},
	{
		path: '/orders/view/:id',
		name: 'order-view',
		component: function () {
			return import(/* webpackChunkName: "order-view" */ '../views/orders/View.vue')
		}
	},
	{
		path: '/orders/history/:id',
		name: 'order-history',
		component: function () {
			return import(/* webpackChunkName: "order-history" */ '../views/orders/History.vue')
		}
	},
	{
		path: '/orders',
		name: 'order-list',
		component: function () {
			return import(/* webpackChunkName: "order-list" */ '../views/orders/List.vue')
		}
	},
	{
		path: '/order/create',
		name: 'order-create',
		component: function () {
			return import(/* webpackChunkName: "order-create" */ '../views/orders/CreateEdit.vue')
		}
	},
	{
		path: '/order/edit/:id',
		name: 'order-edit',
		component: function () {
			return import(/* webpackChunkName: "order-edit" */ '../views/orders/CreateEdit.vue')
		}
	},
].map( item => {
	item.meta = { tab: 'orders' }
	return item
})