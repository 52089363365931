import axios from "axios";
import Vue from "vue";
import envs from "../../env";
import dateFormater from "../helpers/date_formater";
import { getHeaders } from "../helpers/store_utils";

// Ask Andre about implementation of i18n
export default {
  namespaced: true,
  state: () => ({
    selects: {
      type: [
        { label: 'pages.orders.new.typeNormal', value: "normal" },
        { label: 'pages.orders.new.typeFunnel', value: "funnel" },
        // { label: 'Contrato', value: 'contract' },
        // { label: 'Demonstração', value: 'demonstration' },
        // { label: 'Trade-in', value: 'trade_in' },
        // { label: 'Com desconto de anúncio', value: 'advertisement discount' },
        { label: 'pages.orders.new.typeRetail', value: "retail" },
        // { label: 'Consumo interno', value: 'internal' },
      ],
      shippingCompanies: [],
    },
    list: [],
    listArgs: {
      date: {
        month: 0,
        year: 0,
      },
      distributor: 0,
    },
    view: {
      total: 0,
    },
    newLineCtl: null,
    viewCtl: null,
    preventClear: false,
    inserter: {
      rows: [],
    },
    uploader: {
      rows: [],
      // file: null
    },
  }),
  mutations: {
    setTotal(state) {
      var total = 0;
      state.inserter.rows.forEach((row) => {
        total += row.data.rawTotalValue;
      });
      Vue.set(state.view, "total", total);
    },
    productList_newLine() {
      //
    },
    productList_setCtl(state, index) {
      state.newLineCtl = index;
    },
    productList_remove(state, index) {
      state.inserter.rows.splice(index, 1);
    },
    switchView(state, view) {
      return (state.viewCtl = view);
    },
  },
  actions: {
    async get({ state, rootState }, orderID) {
      var edit;
      if (typeof orderID == "object") {
        edit = true;
        orderID = orderID.orderID;
      } else {
        edit = false;
      }

      const { data } = await axios.get(`${envs.API_URL}order/get/${orderID}`, {
        headers: getHeaders({ rootState }),
      });

      const head = data.body.header,
        body = data.body.data[data.body.data.length - 1];

      const order = {
        id: head.id,
        status: head.status,
        distributor_id: head.distributor_id,
        distributor: body.distributor,
        distributor_cnpj: body.distributor_cnpj,
        number: head.number,
        funnel_number: body.funnel_number,
        type: body.order_type,
        payment_method_id: body.payment_method_id,
        payment: body.payment,
        billing_type: body.billing_type,
        shipping_type: body.shipping_type,
        shipping_company: body.shipping_company,
        shipping_name: body.shipping_name,
        final_client: body.final_client,
        voucher_code: body.voucher_code,
        comment: body.comment,
        // total: body.total,
        stock: body.stock ? true : false,
        parcial_billing: body.parcial_billing,
        shipping_code: body.shipping_company,
      };

      var products = [],
        formatedDate,
        formatedValue,
        orderTotal = 0;

      if (body.products.length) {
        body.products.forEach((item, index) => {
          formatedDate = null;
          if (item.scheduled_date) {
            var [year, month, day] = item.scheduled_date
              .slice(0, 10)
              .split("-");
            formatedDate = day + "/" + month + "/" + year;
          }
          orderTotal += item.value * item.quantity;
          if (edit) {
            formatedValue = item.value.toString().replace(".", ",");
          } else {
            formatedValue = item.value;
          }
          products.push({
            index,
            data: {
              partNumber: item.part_number,
              model: item.model,
              description: item.description,
              value: formatedValue,
              quantity: item.quantity,
              totalValue: item.total_value,
              finalClient: item.final_client,
              scheduledDate: formatedDate,
              link: item.link,
              segment: item.segment,
            },
          });
        });
      }

      order.total = orderTotal;

      state.inserter.rows = products;
      state.view = order;

      return data.body;
    },
    async getOrderData({ state, rootState }, orderDataID) {
      const { data } = await axios.get(
        `${envs.API_URL}order/get_order_data/${orderDataID}`,
        {
          headers: getHeaders({ rootState }),
        }
      );

      const orderHeader = data.body.header,
        orderData = data.body.data,
        orderProducts = data.body.products;
      var order = {
        id: orderHeader.id,
        distributor_id: orderHeader.distributor_id,
        type: orderData.order_type,
        number: orderHeader.number,
        payment_method_id: orderData.payment_method_id,
        billing_type: orderData.billing_type,
        shipping_type: orderData.shipping_type,
        shipping_company: orderData.shipping_company,
        total: "R$ " + orderData.total,
        products: orderProducts,
      };

      // console.log( (orderData.total).replace(/\d(?=(\d{3})+\.)/g, '$&,') )

      state.view = order;

      return true;
    },
    async edit({ state }) {
      // const { data } = await axios
      // 	.post(
      // 		`${envs.API_URL}payment_methods/edit`,
      // 		state.view
      // 	)
      // return true
    },
    async remove({ state, rootState }, orderID) {
      const { data } = await axios.get(
        `${envs.API_URL}order/remove/${orderID}`,
        {
          headers: getHeaders({ rootState }),
        }
      );

      return true;
    },
    async getHeaders({ commit, rootState }, file) {
      var formData = new FormData();
      formData.append("files", file, file.name);
      const { data } = await axios.post(
        `${envs.API_URL}order/get_headers`,
        formData,
        {
          headers: getHeaders({ rootState }),
        }
      );

      if (!data.status) {
        if (data.message == "NO_HEADERS") {
          commit(
            "snackbar/show",
            {
              content: "Não foi possivel pegar as colunas desse arquivo!",
              type: "error",
            },
            {
              root: true,
            }
          );
          return false;
        } else if (data.message == "INVALID_HEADERS") {
          commit(
            "snackbar/show",
            {
              content: "Esse arquivo não possui todas as colunas necessárias!",
              type: "error",
            },
            {
              root: true,
            }
          );
          return false;
        } else {
          commit(
            "snackbar/show",
            {
              content: "Houve um erro na leitura do arquivo!",
              type: "error",
            },
            {
              root: true,
            }
          );
          return false;
        }
      }

      return data.body.filter((item) => {
        if (item) {
          return item;
        }
      });
    },
    async dataProccess({ state, commit, rootState }, file) {
      var formData = new FormData();
      formData.append("files", file, file.name);
      formData.append("id", state.view.distributor_id);
      const { data } = await axios.post(
        `${envs.API_URL}order/data_proccess`,
        formData,
        {
          headers: getHeaders({ rootState }),
        }
      );

      if (data.status == false) {
        if (data.message == "REQUIRED_HEADERS_NOT_FOUND") {
          commit(
            "snackbar/show",
            {
              content: "Esse arquivo não possui todas as colunas necessárias!",
              type: "error",
            },
            {
              root: true,
            }
          );
          return false;
        } else if (data.message == "INSUFFICIENT_NUMBER_OF_COLUMNS") {
          commit(
            "snackbar/show",
            {
              content: "Esse arquivo não possui colunas o suficiente!",
              type: "error",
            },
            {
              root: true,
            }
          );
          return false;
        } else if (data.message == "DATA_FAIL") {
          commit(
            "snackbar/show",
            {
              content:
                "Esse arquivo possui dados faltando em colunas obrigatórias!",
              type: "error",
            },
            {
              root: true,
            }
          );
          return false;
        } else {
          return false;
        }
      }

      var totalValue = 0;
      state.uploader.rows = data.body.map((item, index) => {
        totalValue += item.quantity * item.value;
        item.productValue = item.quantity * item.value;
        return {
          index,
          data: {
            partNumber: item.partNumber,
            model: item.model,
            description: item.description,
            quantity: item.quantity,
            scheduledDate: item.scheduledDate,
            segment: item.segment,
            productValue: item.productValue,
            totalValue: item.totalValue,
            link: item.link,
            value: item.value,
            finalClient: item.finalClient,
          },
        };
      });
      state.view.total = totalValue;
      return true;
    },
    async list({ state, rootState }) {
      const { data } = await axios.get(`${envs.API_URL}order/list`, {
        headers: getHeaders({ rootState }),
        params: {
          month: state.listArgs.date.month,
          year: state.listArgs.date.year,
          distributor: state.listArgs.distributor,
        },
      });

      state.list = dateFormater.multiline(data.body);

      return true;
    },
    async waitingApproval({ state, rootState }) {
      const { data } = await axios.get(
        `${envs.API_URL}order/waiting_approval_list`,
        {
          headers: getHeaders({ rootState }),
        }
      );

      state.list = dateFormater.multiline(data.body);

      return true;
    },
    async approve({ state, rootState }, orderID) {
      const { data } = await axios.post(
        `${envs.API_URL}order/approve`,
        {
          order_id: orderID,
        },
        {
          headers: getHeaders({ rootState }),
        }
      );

      return data.body;
    },
    async notApprove({ state, rootState }, orderID) {
      const { data } = await axios.post(
        `${envs.API_URL}order/not_approve`,
        {
          order_id: orderID,
        },
        {
          headers: getHeaders({ rootState }),
        }
      );

      return data.body;
    },
    async draft({ state, rootState }) {
      const products =
        state.viewCtl == "uploader-list"
          ? state.uploader.rows
          : state.inserter.rows;
      const { data } = await axios.post(
        `${envs.API_URL}order/draft`,
        {
          user: rootState.auth.user.id,
          header: state.view,
          products,
        },
        {
          headers: getHeaders({ rootState }),
        }
      );
      return true;
    },
    async create({ state, rootState }) {
      const products =
        state.viewCtl == "uploader-list"
          ? state.uploader.rows
          : state.inserter.rows;
      const { data } = await axios.post(
        `${envs.API_URL}order/create`,
        {
          user: rootState.auth.user.id,
          header: state.view,
          products,
        },
        {
          headers: getHeaders({ rootState }),
        }
      );
      return data;
    },
    async update({ state, rootState }) {
      const { data } = await axios.post(
        `${envs.API_URL}order/update`,
        {
          user: rootState.auth.user.id,
          header: state.view,
          products: state.inserter.rows,
        },
        {
          headers: getHeaders({ rootState }),
        }
      );
      return true;
    },
    async updateDraft({ state, rootState }) {
      const { data } = await axios.post(
        `${envs.API_URL}order/update`,
        {
          keep: true,
          user: rootState.auth.user.id,
          header: state.view,
          products: state.inserter.rows,
        },
        {
          headers: getHeaders({ rootState }),
        }
      );
      return true;
    },
    async shippingList({ state, rootState }, type) {
      const { data } = await axios.get(
        `${envs.API_URL}order/shipping_list/${type}`,
        {
          headers: getHeaders({ rootState }),
        }
      );
      state.selects.shippingCompanies = data.body;
      return true;
    },
    async shippingCompany({ state, rootState }, code) {
      const { data } = await axios.get(
        `${envs.API_URL}order/shipping_company/${code}`,
        {
          headers: getHeaders({ rootState }),
        }
      );
      // state.selects.shippingCompanies = data.body
      return data;
    },
    async remove_order({ state, rootState }, ID) {
      const { data } = await axios.get(
        `${envs.API_URL}order/remove_data/${ID}`,
        {
          headers: getHeaders({ rootState }),
        }
      );

      return data.body;
    },
    clear({ state }, type) {
      // console.log( 'limpando', state.preventClear )
      if (state.preventClear) return;

      switch (type) {
        case "list":
          state.list = [];
          break;
        case "view":
          state.view = {};
          state.newLineCtl = null;
          state.viewCtl = null;
          state.inserter = {
            rows: [],
          };
          state.uploader = {
            rows: [],
          };
          break;
        default:
          break;
      }
    },
  },
};
