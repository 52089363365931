<template>
    <div class="f-default-header">
        <h1>{{title}}</h1>
        <h3>{{subtitle}}</h3>
    </div>
</template>

<script>
export default {
    props: [ 'title', 'subtitle' ]
}
</script>