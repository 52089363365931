<template>
    <div id="notification-tab"  @click="notifications" :class="{ active: alertCount != 0 }">
        <v-avatar>
            <v-icon v-if="true" dark>
                mdi-bell
            </v-icon>
        </v-avatar>
    </div>
</template>

<script>
// await this.$store.dispatch( 'alert/load' )

export default {
    data: () => ({
        intervalCtl: null
    }),
    computed: {
        alertCount() {
            return this.$store.state.alert.list && this.$store.state.alert.list.length
        }
    },
    created() {
        this.intervalCtl = setTimeout(() => {
            this.getAlerts()
        },10*1000)
    },
    watch: {
        '$route.name': function( value ){
            this.getAlerts()
        }
    },
    methods: {
        async getAlerts(){
            if (this.$store.state.auth.isLogged) {
                await this.$store.dispatch( 'alert/load' )
            }
        },
        notifications(){
            this.$router.push({
                name: 'notifications'
            })
        }
    }
}
</script>

<style lang="scss">
#notification-tab {
    margin-left: 10px;
    cursor: pointer;

    &.active {
        .v-avatar {
            background-color: rgb(190, 0, 0);
        }
    }
}
</style>