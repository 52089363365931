<template>
    <v-app-bar
        app
        clipped-left
        color="amber">

        <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
        <span class="title ml-3 mr-5">Fluke&nbsp;<span class="font-weight-light">{{ $t('appTitle') }}</span></span>

        <v-spacer />
        <div class="text-right" id="profile-tab" @click="profile">
            <!-- {{$store.state.user.data.nome}} -->
            <v-avatar color="grey darken-3">
                <v-icon v-if="true" dark>
                    mdi-account-circle
                </v-icon>
                <!-- <img
                    v-else
                    :src="$API_URL + 'img/admin_pic/' + $store.state.user.data.avatar"> -->
            </v-avatar>
        </div>
        <alert-badge />
    </v-app-bar>
</template>

<script>
import AlertBadge from './AlertBadge.vue'

export default {
    components: {
        AlertBadge
    },
    computed: {
        drawer: {
            get() {
                return this.$store.state.system.layout.appbar.drawer
            },
            set( value ) {
                this.$store.state.system.layout.appbar.drawer = value
            }
        }
    },
    methods: {
        profile(){
            this.$router.push({
                name: 'profile-view'
            })
        }
    }
}
</script>
