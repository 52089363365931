export default {
  install(Vue, options) {
    Vue.prototype.$getPermissions = () => {
      var route = options.router.currentRoute.meta.tab;
      if (route) {
        return options.store.state.auth.user.permissions.find(
          (perm) => perm.route == route
        );
      }
    };
  },
};
