<template>
	<div class="global-disabler-wrapper" :class="{ active: disabling }"></div>
</template>

<script>
export default {
	name: "f-disabler",
	computed: {
		disabling() {
			return this.$store.state.system.disabling;
		},
	},
};
</script>

<style lang="scss">
.global-disabler-wrapper {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 4;
	background-color: rgba(0, 0, 0, 0.3);
	display: none;

	&.active {
		display: block;
	}
}
.global-enabled {
  z-index: 5;
}
</style>
