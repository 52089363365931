// layouts
import DefaultLayout from '../layouts/Default.vue'
import LoginLayout from '../layouts/Login.vue'
// components
import ActionsBtnHolder from '../components/shared/ActionsBtnHolder.vue'
import Card from '../components/shared/Card.vue'
import CardSpacer from '../components/shared/CardSpacer.vue'
import CardTitle from '../components/shared/CardTitle.vue'
import CardTitleButton from '../components/shared/CardTitleButton.vue'
import CheckboxTitle from '../components/shared/CheckboxTitle.vue'
import ContentCheckboxTitle from '../components/shared/ContentCheckboxTitle.vue'
import DefaultHeader from '../components/shared/DefaultHeader.vue'
import DeleteButton from '../components/shared/DeleteButton.vue'
import Editor from '../components/shared/Editor.vue'
import ExpandableDiv from '../components/shared/ExpandableDiv.vue'
import LoadingBar from '../components/shared/LoadingBar.vue'

export default {
    install(Vue) {
        // global layouts
        Vue.component('f-default-layout', DefaultLayout)
        Vue.component('f-login-layout', LoginLayout)
        Vue.component('f-privacy-layout', LoginLayout)
        
        // shared components
        Vue.component('f-actions-btn-holder', ActionsBtnHolder)
        Vue.component('f-card', Card)
        Vue.component('f-card-spacer', CardSpacer)
        Vue.component('f-card-title', CardTitle)
        Vue.component('f-card-title-button', CardTitleButton)
        Vue.component('f-checkbox-title', CheckboxTitle)
        Vue.component('f-content-checkbox-title', ContentCheckboxTitle)
        Vue.component('f-default-header', DefaultHeader)
        Vue.component('f-editor', Editor)
        Vue.component('f-delete-button', DeleteButton)
        Vue.component('f-expandable-div', ExpandableDiv)
        Vue.component('f-loading-bar', LoadingBar)
    }
}