import axios from 'axios'
import envs from '../../env'
import { getHeaders } from '../helpers/store_utils'

const store = {
	namespaced: true,
	state: () => ({
		list: [],
		view: {}
	}),
	actions: {
		async load({ state, rootState }, ditributorID ) {
			var distID = (rootState.auth.user && rootState.auth.user.distributorID)?rootState.auth.user.distributorID:0
			const { data } = await axios
				.get( `${envs.API_URL}alert/list/${distID}`, {
					headers: getHeaders({ rootState })
				})

			state.list = data.body
			return true
		},
		async markAsRead({ rootState }, alertID ) {
			const { data } = await axios
				.get( `${envs.API_URL}alert/read/${alertID}`, {
					headers: getHeaders({ rootState })
				})

			return true
		},
		async remove({ state, rootState }, alertID ) {
			var index = state.list.findIndex( item => item.id == alertID )
			state.list.splice(index,1)
			
			const { data } = await axios
				.get( `${envs.API_URL}alert/remove/${alertID}`, {
					headers: getHeaders({ rootState })
				})

			return true
		},
		async getDetails({ rootState }, orderID ){
			// console.log( orderID, 'bah' )
			const { data } = await axios
				.get( `${envs.API_URL}alert/get_details/${orderID}`, {
					headers: getHeaders({ rootState })
				})
			return data.body
		}
	}
}

export default store
