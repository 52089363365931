import axios from 'axios'
import envs from '../../env'
import { getHeaders } from '../helpers/store_utils'

export default {
	namespaced: true,
	state: () => ({
		profile: null,
	}),
	getters: {
    getProfile(state) {
      return state.profile
    }
  },
	mutations: {
    setProfile (state, profile) {
			state.profile = profile
		}
  },
	actions: {
		async getAuthURL({ rootState }) {
			const { data } = await axios
				.get( 
					`${envs.API_URL}box/auth`,
					{
						headers: getHeaders({ rootState })
					}
				)
			return data
		},
		async authCallback({ rootState }, code) {
			const { data } = await axios
				.get( 
					`${envs.API_URL}box/callback/${code}`,
					{
						headers: getHeaders({ rootState })
					}
				)
			return data
		},
		async profileInfo({ commit, rootState }) {
			const { data } = await axios
				.get( 
					`${envs.API_URL}box/info`,
					{
						headers: getHeaders({ rootState })
					}
				)
      if (data.status) {
        commit('setProfile', data.body)
      }
			return data
		},
	}
}
