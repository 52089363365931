<template>
    <div class="remove-button" v-if="isVisible">
        <div class="remove-button-btn">
            <button>
                <slot />
            </button>
        </div>
    </div>
</template>

<script>
export default {
    data: () => ({
        isVisible: false
    }),
    created() {
        var permission = false
        this.$store.state.auth.user.permissions.forEach( perm => {
            if( 
                perm.route == 'delete' &&
                perm.write
            ){
                permission = true
            }
        })

        this.isVisible = permission
    }
}
</script>