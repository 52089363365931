import axios from 'axios'
import envs from '../../env'
import dateFormater from '../helpers/date_formater'
import { getHeaders } from '../helpers/store_utils'

export default {
	namespaced: true,
	state: () => ({
		list: [],
		view: {}
	}),
	getters: {},
	mutations: {},
	actions: {
		async get({state, rootState}, paymentMethodID ) {
			const { data } = await axios
				.get( `${envs.API_URL}demo_intervals/get/${paymentMethodID}`, {
					headers: getHeaders({ rootState })
				})

			state.view = data.body

			return true
		},
		async edit({ state, rootState }) {
			const { data } = await axios
				.post(
					`${envs.API_URL}demo_intervals/edit`,
					state.view, 
					{
						headers: getHeaders({ rootState })
					}
				)

			return true
		},
		async remove({ state, rootState }) {
			const { data } = await axios
				.get( `${envs.API_URL}demo_intervals/remove/${state.view.id}`, {
					headers: getHeaders({ rootState })
				})

			return true
		},
		async list({state, rootState}) {
			const { data } = await axios
				.get( `${envs.API_URL}demo_intervals/list`, {
					headers: getHeaders({ rootState })
				})

			state.list = dateFormater.fields( data.body, ['begin','end'] )

			return true
		},
		async create({ state, rootState }) {
			const { data } = await axios
				.post( `${envs.API_URL}demo_intervals/create`, state.view, {
					headers: getHeaders({ rootState })
				})
			return true
		},
		clear({state}, type){
			switch (type) {
				case 'list':
					state.list = []
					break
				case 'view':
					state.view = {}
					break
				default:
					break
			}
		}
	}
}
