import axios from 'axios'
import store from '../store/index'
import router from '../router/index'

export default {
  install(Vue, options) {
    axios.interceptors.response.use(response => {
      try {
        if(
          response.data &&
          response.data.status === false &&
          (
            response.data.message == 'invalid_token' ||
            response.data.message == 'not_logged'
          )
        ) {
          router.push({
            name: 'login'
          })
        }
      } catch( e ) {
        //
      }
      return response;
    }, async ( error, code ) => {
      store.commit('snackbar/show', {
        content: 'Houve erro na requisição',
        type: 'error'
      })
      return await error
    })
  }
}

