<template>
    <div>
        <f-checkbox-title v-model="expandCtl">
            {{_title}}
        </f-checkbox-title>
        
        <f-expandable-div :expanded="expandCtl">
            <slot />
        </f-expandable-div>
    </div>
</template>

<script>
export default {
    props: [ 'title', 'value' ],
    data: () => ({
        expandCtl: false,
        _title: '',
    }),
    created() {
        this._title = this.title
        this.expandCtl = this.value
    },
    watch: {
        expandCtl( val ){
            this.$emit( 'input', val )
        },
        value( val ){
            this.expandCtl = this.value
        }
    }
}
</script>