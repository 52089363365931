export default {
  data: () => ({
    menuCtl: {
      withChildren: [],
      actives: {},
    },
  }),
  computed: {
    drawer: {
      get() {
        return this.$store.state.system.layout.appbar.drawer;
      },
      set(value) {
        this.$store.state.system.layout.appbar.drawer = value;
      },
    },
    items() {
      return this.$store.getters["system/menuItens"];
    },
  },
  watch: {
    $route: function () {
      this.openMenu();
    },
  },
  created() {
    this.menuCtl.withChildren = this.items
      .filter(
        (item) => typeof item.children != "undefined" && item.children.length
      )
      .map((item) => item.tab);
    for (var item in this.menuCtl.withChildren) {
      this.menuCtl.actives[this.menuCtl.withChildren[item]] = false;
    }
    this.openMenu();
  },
  methods: {
    openMenu() {
      var currentRouteTab = this.$route.meta.tab;
      for (var item in this.menuCtl.actives) {
        this.menuCtl.actives[item] = item == currentRouteTab;
      }
    },
    navigateTo(routeName) {
      this.keepProducts = false;
      this.$router
        .push({
          name: routeName,
        })
        .catch((e) => {
          // console.log(e)
        });
    },
  },
};
