export default [
	{
		path: '/marketing/items',
		name: 'mkt-items-list',
		component: function () {
			return import(/* webpackChunkName: "mkt-items-list" */ '../views/mkt/items/List.vue')
		}
	},
	{
		path: '/marketing/items/create',
		name: 'mkt-items-create',
		component: function () {
			return import(/* webpackChunkName: "mkt-items-create" */ '../views/mkt/items/CreateEdit.vue')
		}
	},
	{
		path: '/marketing/items/edit/:id',
		name: 'mkt-items-edit',
		component: function () {
			return import(/* webpackChunkName: "mkt-items-edit" */ '../views/mkt/items/CreateEdit.vue')
		}
	},
	{
		path: '/marketing/suport',
		name: 'mkt-contact-list',
		component: function () {
			return import(/* webpackChunkName: "mkt-contact-list" */ '../views/mkt/contact/List.vue')
		}
	},
	{
		path: '/marketing/suport/view/:id',
		name: 'mkt-contact-view',
		component: function () {
			return import(/* webpackChunkName: "mkt-contact-view" */ '../views/mkt/contact/View.vue')
		}
	},
	{
		path: '/marketing/newsletter',
		name: 'mkt-newsletter-list',
		component: function () {
			return import(/* webpackChunkName: "mkt-newsletter-list" */ '../views/mkt/newsletter/List.vue')
		}
	},
	{
		path: '/marketing/newsletter/view/:id',
		name: 'mkt-newsletter-view',
		component: function () {
			return import(/* webpackChunkName: "mkt-newsletter-view" */ '../views/mkt/newsletter/View.vue')
		}
	},
	{
		path: '/marketing',
		name: 'mkt-index',
		component: function () {
			return import(/* webpackChunkName: "mkt-index" */ '../views/mkt/Index.vue')
		}
	},
	{
		path: '/marketing/languages',
		name: 'mkt-items-languages-list',
		component: function () {
			return import(/* webpackChunkName: "mkt-items-languages" */ '../views/mkt/items/languages/List.vue')
		}
	},
	{
		path: '/marketing/languages/create',
		name: 'mkt-items-languages-create',
		component: function () {
			return import(/* webpackChunkName: "mkt-items-languages" */ '../views/mkt/items/languages/CreateEdit.vue')
		}
	},
	{
		path: '/marketing/tags',
		name: 'mkt-items-tags-list',
		component: function () {
			return import(/* webpackChunkName: "mkt-items-tags-list" */ '../views/mkt/items/tags/List.vue')
		}
	},
	{
		path: '/marketing/tags/create',
		name: 'mkt-items-tags-create',
		component: function () {
			return import(/* webpackChunkName: "mkt-items-tags-create" */ '../views/mkt/items/tags/CreateEdit.vue')
		}
	},
	// {
	// 	path: '/order/create',
	// 	name: 'order-create',
	// 	component: function () {
	// 		return import(/* webpackChunkName: "order-create" */ '../views/orders/CreateEdit.vue')
	// 	}
	// },
	// {
	// 	path: '/order/edit/:id',
	// 	name: 'order-edit',
	// 	component: function () {
	// 		return import(/* webpackChunkName: "order-edit" */ '../views/orders/CreateEdit.vue')
	// 	}
	// },
].map( item => {
	item.meta = { tab: 'mkt' }
	return item
})