import axios from 'axios'
import envs from '../../env'
import { getHeaders } from '../helpers/store_utils'

export default {
  namespaced: true,
  state: () => ({
    loading: false,
    list: [],
  }),
  actions: {
    async list({ rootState }, { name }) {
      const { data } = await axios
        .get(
          `${envs.API_URL}option?name=${name}`,
          { headers: getHeaders({ rootState }) }
        )
      return data
    },
    async save({ rootState }, { name, payload }) {
      const { data } = await axios
        .post(
          `${envs.API_URL}option?name=${name}`,
          payload,
          { headers: getHeaders({ rootState }) }
        )
      return data
    },
  }
}
