import axios from 'axios'
import envs from '../../env'
import dateFormater from '../helpers/date_formater'
import { getHeaders } from '../helpers/store_utils'

export default {
	namespaced: true,
	state: () => ({
		list: [],
		view: {}
	}),
	getters: {},
	mutations: {},
	actions: {
		// async get({state}, paymentMethodID ) {
		// 	const { data } = await axios
		// 		.get( `${envs.API_URL}payment_methods/get/${paymentMethodID}` )

		// 	state.view = {
		// 		id: data.body.id,
		// 		protheusID: data.body.protheus_id,
		// 		description: data.body.description
		// 	}

		// 	return data
		// },
		// async edit({ state }) {
		// 	const { data } = await axios
		// 		.post(
		// 			`${envs.API_URL}payment_methods/edit`,
		// 			state.view
		// 		)

		// 	return true
		// },
		// async remove({ state }) {
		// 	const { data } = await axios
		// 		.get( `${envs.API_URL}payment_methods/remove/${state.view.id}` )

		// 	return true
		// },
		async list({state, rootState}) {
			const { data } = await axios
				.get( `${envs.API_URL}mkt/items_language_list`, {
					headers: getHeaders({ rootState })
				})

			state.list = dateFormater.multiline( data.body )

			return data.body
		},
		async create({ state, rootState }) {
			const { data } = await axios
				.post( `${envs.API_URL}mkt/items_language_create`, state.view, {
					headers: getHeaders({ rootState })
				})
			return true
		},
		clear({state}, type){
			switch (type) {
				case 'list':
					state.list = []
					break
				case 'view':
					state.view = {}
					break
				default:
					break
			}
		}
	}
}
