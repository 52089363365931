import Vue from 'vue'
import VueRouter from 'vue-router'
import Store from '../store/index'

/**
 * TODO: Remover isso
 */
import HomePage from '../views/Home.vue'
import NoPermissionPage from '../views/NoPermission.vue'

import approvals from './approvals'
import contact from './contact'
import distributor from './distributor'
import inventory from './inventory'
import login from './login'
import marketing from './mkt'
import notification from './notification'
import order from './order'
import pos from './pos'
import privacy from './privacy'
import profile from './profile'
import settings from './settings'
import user from './user'

import temp from './temp'
import store from '../store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomePage
  },
  {
    path: '/permission_denied',
    name: 'no-permission',
    component: NoPermissionPage
  },
  ...approvals,
  ...contact,
  ...distributor,
  ...inventory,
  ...login,
  ...marketing,
  ...notification,
  ...order,
  ...pos,
  ...profile,
  ...privacy,
  ...user,
  ...settings,
  ...temp
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  // console.log( to.name )
  if (
    ![
      'login',
      'logout',
      'login-forgot',
      'login-reset',
      'login-reset-password',
      'login-set-password',
      'privacy-index'
    ].includes(to.name)
  ) {
    var isLogged = await Store.dispatch('auth/check_login')

    let currentLang = localStorage.getItem('currentLang')

    if (currentLang == null) {
      localStorage.setItem('currentLang', 'pt')
    }

    // console.log( to.name, to.meta.tab, Store.state.auth.user.permissions )

    // find permission
    var isAllowed = false
    if (
      [
        'no-permission',
        'profile-view',
        'profile-set-password',
        'notifications',
        'home'
      ].includes(to.name)
    ) {
      isAllowed = true
    } else {
      Store.state.auth.user.permissions.forEach(perm => {
        if (perm.route == to.meta.tab) {
          isAllowed = perm.read
        }
      })
    }

    // console.log( isAllowed )

    if (!isLogged) {
      next({ name: 'login' })
    } else if (!isAllowed) {
      next({ name: 'no-permission' })
    } else next()
  } else next()
})

export default router
