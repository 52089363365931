import axios from 'axios'
import envs from '../../env'
import { getHeaders } from '../helpers/store_utils'

export default {
  namespaced: true,
  state: () => ({
    isLogged: false,
    token: '',
    user: null,
    permissions: [
      { title: 'Aprovações', route: 'approvals', write: false, read: false, hideOnDistributor: true },
      { title: 'Pedidos', route: 'orders', write: false, read: false, hideOnDistributor: false },
      { title: 'Usuários', route: 'users', write: false, read: false, hideOnDistributor: false },
      { title: 'Estoque', route: 'inventory', write: false, read: false, hideOnDistributor: false },
      { title: 'POS', route: 'pos', write: false, read: false, hideOnDistributor: false },
      { title: 'Marketing', route: 'mkt', write: false, read: false, hideOnDistributor: true },
      { title: 'Marketing - site', route: 'mktsite', write: false, read: false, hideOnDistributor: false },
      { title: 'Distribuidores', route: 'distributors', write: false, read: false, hideOnDistributor: true },
      { title: 'Contato', route: 'contact', write: false, read: false, hideOnDistributor: false },
      { title: 'Configurações', route: 'settings', write: false, read: false, hideOnDistributor: true },
      { title: 'Exclusão', route: 'delete', write: false, read: false, hideOnDistributor: true },
    ],
    lang: 'ES'
  }),
  getters: {},
  mutations: {},
  actions: {
    async resetPassword({ }, email) {
      const { data } = await axios
        .post(
          `${envs.API_URL}auth/resetpassword`,
          { email },
        )

      return true
    },
    async setPasswordToken({ rootState }, bodyData) {
      const { data } = await axios
        .post(
          `${envs.API_URL}auth/setpasswordtoken`,
          bodyData,
          {
            headers: getHeaders({ rootState })
          }
        )

      return true
    },
    async setPassword({ rootState }, passData) {
      const { data } = await axios
        .post(`${envs.API_URL}auth/setpassword`, passData, {
          headers: getHeaders({ rootState })
        })

      return true
    },
    async logout({ state }) {
      // save on store
      state.isLogged = false
      state.token = ''
      state.user = null

      // save on local storage
      localStorage.setItem('flukePortDistUser', false)
      localStorage.removeItem('flukePortDistUser')
    },
    async login({ state }, userData) {

      const { data } = await axios
        .post(`${envs.API_URL}auth/login`, userData)

      if (data.status) {
        var permissions = JSON.parse(data.body.permissions)

        permissions = permissions.map((item) => {
          if (item.route === 'mkt') {
            const permissiontMktSite = permissions.find((item) => {
              return item.route === 'mktsite'
            })
            if (permissiontMktSite) {
              if (permissiontMktSite.read) {
                item.read = true
              }
            }
          }
          return item
        })

        let lang = 'es'
        if (typeof data.body.lang !== 'undefined') {
          lang = data.body.lang;
        }

        // save on store
        state.isLogged = true
        state.token = data.body.token
        state.user = {
          id: data.body.id,
          permissions,
          distributorID: data.body.distributor_id,
          acceptance: data.body.acceptance
        }
        state.lang = lang

        // save on local storage
        localStorage.setItem(
          'flukePortDistUser',
          JSON.stringify({
            user: {
              id: data.body.id,
              permissions,
              distributorID: data.body.distributor_id
            },
            token: data.body.token,
            lang: lang
          })
        )

        localStorage.setItem('currentLang', lang)

        return {
          status: true,
          reset: data.body.reset
        }
      }

      return false
    },
    check_login({ state }) {
      // check is logged on store
      if (state.isLogged && state.user !== null) return true

      // check is set on localstorage
      const localStorage_user = localStorage.getItem('flukePortDistUser')
      if (localStorage_user == null) {
        return false
      } else {
        const localStorage_userObj = JSON.parse(localStorage_user)
        state.isLogged = true
        state.token = localStorage_userObj.token
        state.user = localStorage_userObj.user
      }

      return state.isLogged
    },
    async resetToken({ rootState }) {
      const { data } = await axios
        .get(`${envs.API_URL}auth/reset_access_token`, {
          headers: getHeaders({ rootState })
        })
      return data.body
    },
    async getToken({ rootState }) {
      const { data } = await axios
        .get(`${envs.API_URL}auth/get_access_token`, {
          headers: getHeaders({ rootState })
        })
      return data.body
    },
  }
}
