export default [
	{
		path: '/dev',
		name: 'temp-main',
		component: function () {
			return import(/* webpackChunkName: "user-list" */ '../views/dev/Main.vue')
		}
	},
	{
		path: '/dev/orderinput',
		name: 'temp-input-order',
		component: function () {
			return import(/* webpackChunkName: "user-create" */ '../views/dev/InputOrder.vue')
		}
	},
].map( item => {
	item.meta = { tab: 'temp' }
	return item
})