import axios from 'axios'
import envs from '../../env'
import { getHeaders } from '../helpers/store_utils'

export default {
	namespaced: true,
	state: () => ({
		passData: {},
		view: {}
	}),
	getters: {},
	mutations: {},
	actions: {
		async show({state,rootState}){
			const { data } = await axios
				.get( `${envs.API_URL}user/get/${rootState.auth.user.id}`, {
					headers: getHeaders({ rootState })
				})

			state.view = {
				id: data.body.id,
				name: data.body.name,
				email: data.body.email,
        lang: data.body.lang
			}
		},
		async save({state, rootState}){
			const userData = {
				name: state.view.name,
				id: state.view.id,
        lang: localStorage.currentLang
			}
			const { data } = await axios
				.post(
					`${envs.API_URL}user/saveprofile`,
					userData,
					{
						headers: getHeaders({ rootState })
					}
				)

			return true
		}
	}
}
