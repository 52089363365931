import axios from 'axios'
import envs from '../../env'
import dateFormater from '../helpers/date_formater'
import { getHeaders } from '../helpers/store_utils'
import { isHighlightGroup, isVerticalGroup } from '../helpers/mkt_utils'

export default {
	namespaced: true,
	state: () => ({
		list: [],
		view: {
			id: null,
			baseData: {
				types: [],
				language: null
			},
			active: true,
			type: null,
			title: {},
			description: {},
			link: {},
			link_box: {},
			long_description: {},
			date: null
		},
		sending: false
	}),
	getters: {
		getSending({ sending }) {
			return sending
		}
	},
	mutations: {
		setViewType( state, value ){
			state.view.type = value
		},
		setViewLanguage( state, value ){
			state.view.baseData.language = value
		},
		setSending( state, flag ){
			state.sending = flag
		}
	},
	actions: {
		async language_list({ state, rootState }) {
			const { data } = await axios
				.get( `${envs.API_URL}mkt/items_language_list`, {
					headers: getHeaders({ rootState })
				})
			state.list = dateFormater.multiline( data.body )

			return true
		},
		async items_listTypes({ state, rootState }) {
			const { data } = await axios
				.get( `${envs.API_URL}mkt/items_list_types`, {
					headers: getHeaders({ rootState })
				})
			state.view.baseData.types = data.body

			return true
		},
		async save({ commit, state, dispatch, rootState }){
			commit('setSending', true)
			const formData = new FormData()
			var insertData
			if( isHighlightGroup(+state.view.type) ){
				insertData = JSON.stringify({
					type: state.view.type,
					title: state.view.title,
					description: state.view.description,
					link: state.view.link,
					link_box: state.view.link_box,
					tags: state.view.tags,
					status: state.view.active ? 'enabled' : 'disabled',
				})
				formData.append( 'files', state.view.file )
				formData.append( 'insertData', insertData )
			} else if( isVerticalGroup(+state.view.type) ){
				insertData = JSON.stringify({
					type: state.view.type,
					title: state.view.title,
					description: state.view.description,
					long_description: state.view.long_description,
					link: state.view.link,
					link_box: state.view.link_box,
					tags: state.view.tags,
					status: state.view.active ? 'enabled' : 'disabled',
				})
				formData.append( 'files', state.view.file )
				formData.append( 'insertData', insertData )
			} else {
				insertData = JSON.stringify({
					type: state.view.type,
					title: state.view.title,
					description: state.view.description,
					link: state.view.link,
					link_box: state.view.link_box,
					date: state.view.date,
					tags: state.view.tags,
					status: state.view.active ? 'enabled' : 'disabled',
				})
				formData.append( 'files', state.view.file )
				formData.append( 'insertData', insertData )
			}
			const { data } = await axios
				.post(
					`${envs.API_URL}mkt/items_create`,
					formData,
					{
						headers: getHeaders({
							rootState,
							'Content-Type': 'multipart/form-data'
						})
					}
				)
			// dispatch('clear', 'view')
			commit('setSending', false)
			return true
		},
		async edit({ commit, state, dispatch, rootState }){
			commit('setSending', true)
			if( typeof(state.view.image) == 'object' ){
				state.view.file = state.view.image
			}
			const formData = new FormData()
			var insertData
			if( isHighlightGroup(+state.view.type) ){
				insertData = JSON.stringify({
					id: state.view.id,
					active: state.view.active,
					type: state.view.type,
					title: state.view.title,
					description: state.view.description,
					link: state.view.link,
					link_box: state.view.link_box,
					tags: state.view.tags,
					status: state.view.active ? 'enabled' : 'disabled',
				})
				formData.append( 'files', state.view.file )
				formData.append( 'insertData', insertData )
			} else if( isVerticalGroup(+state.view.type) ){
				insertData = JSON.stringify({
					id: state.view.id,
					active: state.view.active,
					type: state.view.type,
					title: state.view.title,
					description: state.view.description,
					long_description: state.view.long_description,
					link: state.view.link,
					link_box: state.view.link_box,
					tags: state.view.tags,
					status: state.view.active ? 'enabled' : 'disabled',
				})
				formData.append( 'files', state.view.file )
				formData.append( 'insertData', insertData )
			} else {
				insertData = JSON.stringify({
					id: state.view.id,
					active: state.view.active,
					type: state.view.type,
					title: state.view.title,
					description: state.view.description,
					link: state.view.link,
					link_box: state.view.link_box,
					date: state.view.date,
					tags: state.view.tags,
					status: state.view.active ? 'enabled' : 'disabled',
				})
				formData.append( 'files', state.view.file )
				formData.append( 'insertData', insertData )
			}
			const { data } = await axios
				.post(
					`${envs.API_URL}mkt/items_edit`,
					formData,
					{
						headers: getHeaders({
							rootState,
							'Content-Type': 'multipart/form-data'
						})
					}
				)

			// dispatch('clear', 'view')
			commit('setSending', false)
			return true
		},
		async items_get({ state, rootState }, itemID ){
			state.view.id = itemID
			const { data } = await axios
				.get(
					`${envs.API_URL}mkt/items_get/${state.view.id}`,
					{
						headers: getHeaders({ rootState })
					}
				)

			state.view.type = data.body.type
			state.view.active = data.body.active
			state.view.title = data.body.fields.title
			state.view.description = data.body.fields.description
			state.view.link = data.body.fields.link
			state.view.link_box = data.body.fields.link_box
			state.view.tags = data.body.tags
			state.view.active = data.body.status === 'enabled'


			// state.view.image = data.body.customFields.image
			state.view.image = `${envs.API_URL}images/${data.body.customFields.image}`

			if( isVerticalGroup(+state.view.type) ){
				state.view.long_description = data.body.customFields.long_description
			}
			if( state.view.type == 3 ){
				state.view.date = data.body.customFields.date
			}
		},
		clear({state}, type){
			switch (type) {
				case 'list':
					state.list = []
					break
				case 'view':
					state.view = {
						id: null,
						baseData: {
							types: [],
							language: null
						},
						active: true,
						type: null,
						title: {},
						description: {},
						link: {},
						link_box: {},
						long_description: {},
						date: null
					}
					break
				default:
					break
			}
		},
		isHighlightGroup({}, type) {
			const result = isHighlightGroup(type)
			return result
		},
		isVerticalGroup({}, type) {
			const result = isVerticalGroup(type)
			return result
		},
		async remove({ rootState }, ID) {
			const { data } = await axios
				.get( `${envs.API_URL}mkt/items_remove/${ID}`, {
					headers: getHeaders({ rootState })
				})

			return true
		},
	},
}
