export const mktTypes = {
	HIGHLIGHT: 1, // Destaque
	VERTICAL: 2, // Vertical
	EVENT: 3, // Evento
	IMAGEBANK: 4, // Banco de Imagens
	MATERIAL: 5, // Materiais
	CALENDAR: 6, // Calendário de marketing
	INSTITUTIONAL: 7, // Institucional
	SALETOOL: 8, // Ferramentas de vendas
	TICKET: 9, // Abertura de chamados
}

export const isHighlightGroup = (type) => 
	[
		mktTypes.HIGHLIGHT,
		mktTypes.CALENDAR,
		mktTypes.INSTITUTIONAL,
		mktTypes.TICKET,
	].includes(type)

export const isVerticalGroup = (type) => 
	[
		mktTypes.VERTICAL,
		mktTypes.MATERIAL,
		mktTypes.SALETOOL,
	].includes(type)
