export default [
	{
		path: '/distributors',
		name: 'distributor-list',
		component: function () {
			return import(/* webpackChunkName: "distributor-list" */ '../views/distributors/List.vue')
		}
	},
	{
		path: '/distributor/create',
		name: 'distributor-create',
		component: function () {
			return import(/* webpackChunkName: "distributor-create" */ '../views/distributors/CreateEdit.vue')
		}
	},
	{
		path: '/distributor/edit/:id',
		name: 'distributor-edit',
		component: function () {
			return import(/* webpackChunkName: "distributor-edit" */ '../views/distributors/CreateEdit.vue')
		}
	},
].map( item => {
	item.meta = { tab: 'distributors' }
	return item
})