export default {
	namespaced: true,
	state: () => ({
		list: [],
		selected: [],
		view: {
			id: "",
			name: {
				pt: "",
				en: "",
				es: "",
			},
		},
	}),
	actions: {
		clear({ state }, type) {
			switch (type) {
				case "list":
					state.list = [];
					break;
				case "view":
					state.view = {
						id: "",
						name: {
							pt: "",
							en: "",
							es: "",
						},
					};
					break;
				default:
					break;
			}
		},
	},
};
