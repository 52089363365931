export default [
	{
		path: '/login',
		name: 'login',
		component: function () {
			return import(/* webpackChunkName: "login" */ '../views/login/Login.vue')
		}
	},
	{
		path: '/login/setpassword',
		name: 'login-set-password',
		component: function () {
			return import(/* webpackChunkName: "login-set-password" */ '../views/login/SetPassword.vue')
		}
	},
	{
		path: '/login/reset/:token',
		name: 'login-reset-password',
		component: function () {
			return import(/* webpackChunkName: "login-reset-password" */ '../views/login/Reset.vue')
		}
	},
	{
		path: '/login/forgot',
		name: 'login-forgot',
		component: function () {
			return import(/* webpackChunkName: "login-forgot" */ '../views/login/Forgot.vue')
		}
	},
].map( item => {
	item['meta'] = { layout: 'f-login' }
	return item
})