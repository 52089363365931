import axios from "axios";
import envs from "../../env";
import dateFormater from "../helpers/date_formater";
import { getHeaders } from "../helpers/store_utils";

export default {
  namespaced: true,
  state: () => ({
    list: [],
    view: {
      id: '',
      protheusID: '',
      description: {
        pt: '',
        en: '',
        es: ''
      }
    },
  }),
  getters: {},
  mutations: {},
  actions: {
    async get({ state, rootState }, paymentMethodID) {
      const { data } = await axios.get(
        `${envs.API_URL}payment_methods/get/${paymentMethodID}`,
        {
          headers: getHeaders({ rootState }),
        }
      );

      state.view = {
        id: data.body.id,
        protheusID: data.body.protheus_id,
        description: {
          pt: JSON.parse(data.body.description).filter(item => item.language == 'pt')[0].label,
          en: JSON.parse(data.body.description).filter(item => item.language == 'en')[0].label,
          es: JSON.parse(data.body.description).filter(item => item.language == 'es')[0].label,
        },
      };
    },
    async edit({ state, rootState }) {
      let description = []
      Object.keys(state.view.description).map((key) => {
        let payloadDescription = {
          language: key,
          label: state.view.description[key]
        }
        description.push(payloadDescription)
      })

      let payload = {
        id: state.view.id,
        protheusID: state.view.protheusID,
        description: JSON.stringify(description)
      }

      const { data } = await axios.post(
        `${envs.API_URL}payment_methods/edit`,
        payload,
        {
          headers: getHeaders({ rootState }),
        }
      );

      return true;
    },
    async remove({ state, rootState }) {
      const { data } = await axios.get(
        `${envs.API_URL}payment_methods/remove/${state.view.id}`,
        {
          headers: getHeaders({ rootState }),
        }
      );

      return true;
    },
    async list({ state, rootState }) {
      const { data } = await axios.get(`${envs.API_URL}payment_methods/list`, {
        headers: getHeaders({ rootState }),
      });

      state.list = []

      data.body.forEach((payment) => {
        let description = JSON.parse(payment.description);
        let paymentTranslated = description.filter((item) => {
          return item.language === localStorage.getItem("currentLang") || item.language === "pt";
        });

        state.list.push({
          id: payment.id,
          protheusID: payment.protheus_id,
          description: paymentTranslated[0].label,
          created_at: dateFormater.fromDB(payment.created_at),
        });
      });

      return state.list;
    },
    async create({ state, rootState }) {
      let description = []
      Object.keys(state.view.description).map((key) => {
        let payloadDescription = {
          language: key,
          label: state.view.description[key]
        }
        description.push(payloadDescription)
      })

      let payload = {
        protheusID: state.view.protheusID,
        description: JSON.stringify(description)
      }

      const { data } = await axios.post(
        `${envs.API_URL}payment_methods/create`,
        payload,
        {
          headers: getHeaders({ rootState }),
        }
      );
      return true;
    },
    clear({ state }, type) {
      switch (type) {
        case "list":
          state.list = [];
          break;
        case "view":
          state.view = {
            id: '',
            protheusID: '',
            description: {
              pt: '',
              en: '',
              es: ''
            }
          };
          break;
        default:
          break;
      }
    },
  },
};
