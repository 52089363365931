import axios from 'axios'
import envs from '../../env'
import { getHeaders } from '../helpers/store_utils'
import langs from '../data/languages'
import { i18n } from '../main'

export default {
	namespaced: true,
	state: () => ({
		disabling: false,
		langs,
		seletedLang: null,
		loading: {
			type: null,
			done: true
		},
		layout: {
			appbar: {
				drawer: null
			},
			menu: {
				items: [
					{
						icon: 'mdi-dashboard',
						text: 'Home',
						route: 'home',
						tab: 'home',
					},
					{
						icon: 'mdi-plus',
						text: 'Dev Only',
						route: 'temp-main',
						tab: 'temp',
						children: [
							{
								text: ': order input',
								route: 'temp-input-order',
								tab: 'temp'
							},
						]
					},
					{
						icon: 'mdi-list-status',
            text: 'approvals',
						route: 'approval-list',
						tab: 'approvals',
						children: [
							{
								text: 'list',
								route: 'approval-list',
								tab: 'approvals'
							},
						]
					},
					{
						icon: 'mdi-card-multiple',
						text: 'orders',
						route: 'order-list',
						tab: 'orders',
						children: [
							{
								text: 'list',
								route: 'order-list',
								tab: 'orders'
							},
							{
								text: 'new',
								route: 'order-create',
								tab: 'orders'
							},
						]
					},
					{
						icon: 'mdi-archive',
						text: 'inventory',
						route: 'inventory-list',
						tab: 'inventory',
						children: [
							{
								text: 'list',
								route: 'inventory-list',
								tab: 'inventory'
							},
							{
								text: 'new',
								route: 'inventory-upload',
								tab: 'inventory'
							},
						]
					},
					{
						icon: 'mdi-store',
						text: 'pos',
						route: 'pos-list',
						tab: 'pos',
						children: [
							{
								text: 'list',
								route: 'pos-list',
								tab: 'pos'
							},
							{
								text: 'new',
								route: 'pos-upload',
								tab: 'pos'
							},
						]
					},
					{
						icon: 'mdi-bullhorn',
						text: 'marketing',
						route: 'mkt-index',
						tab: 'mkt',
						children: [
							{
								text: 'portal',
								route: 'mkt-index',
								tab: 'mkt'
							},
							{
								text: 'itens',
								route: 'mkt-items-list',
								tab: 'mkt'
							},
							{
								text: 'tags',
								route: 'mkt-items-tags-list',
								tab: 'mkt'
							},
							// {
							// 	text: 'languages',
							// 	route: 'mkt-items-languages-list',
							// 	tab: 'mkt'
							// },
							{
								text: 'suport',
								route: 'mkt-contact-list',
								tab: 'mkt'
							},
							{
								text: 'newsletter',
								route: 'mkt-newsletter-list',
								tab: 'mkt'
							},
						]
					},
					{
						icon: 'mdi-account-multiple',
						text: 'users',
						route: 'user-list',
						tab: 'users',
						children: [
							{
								text: 'list',
								route: 'user-list',
								tab: 'users'
							},
							{
								text: 'new',
								route: 'user-create',
								tab: 'users'
							},
						]
					},
					{
						icon: 'mdi-garage-variant',
            text: 'distributors',
						route: 'distributor-list',
						tab: 'distributors',
						children: [
							{
								text: 'list',
								route: 'distributor-list',
								tab: 'distributors'
							},
							{
								text: 'new',
								route: 'distributor-create',
								tab: 'distributors'
							},
						]
					},
					{
						icon: 'mdi-help-circle',
						text: 'contact',
						route: 'contact-list',
						tab: 'contact'
					},
					{
						icon: 'mdi-cog',
            text: 'settings',
						route: 'settings',
						tab: 'settings'
					},
				]
			}
		}
	}),
	getters: {
		menuItens(state, _, rootState) {
			var menu = []
			var permissionedItem = undefined
			if (rootState.auth.user) {
				const { permissions } = rootState.auth.user
				const permissiontMkt = permissions.find((item) => {
					return item.route === 'mkt'
				})
				const permissiontMktSite = permissions.find((item) => {
					return item.route === 'mktsite'
				})
				state.layout.menu.items.forEach( item => {
					permissionedItem = permissions.find( perm => perm.route == item.tab )
					if( permissionedItem && permissionedItem.read ) {
						const newItem = { ...item }
						if (newItem.tab === 'mkt' && permissiontMkt && permissiontMktSite) {
							if (permissiontMktSite.read && !permissiontMkt.write) {
								newItem.children = []
							}
						}
						menu.push(newItem)
					}
				})
			}
			return menu
		},
		langs: ({ langs }) => langs,
		selectedInputsLang: ({ selectedLang }) => selectedLang,
	},
	mutations: {
		selectLang(state, value) {
			i18n.locale = value;
		},
		selectInputsLang(state, value) {
			state.selectedLang = value;
		},
	},
	actions: {
		loading({state}, type) {
			switch (type) {
				case 'data':
					state.loading.type =  'data'
					state.loading.done =  false
					break
				case 'done':
					state.loading.done =  true
					break
			}
		},
		async distributorMigrateCheck({ rootState }) {
			const { data } = await axios
				.get( `${envs.API_URL}distributor/migrate/need_check`, {
					headers: getHeaders({ rootState })
				})
			return data
		},
		async distributorMigrateUp({ rootState }) {
			const { data } = await axios
				.get( `${envs.API_URL}distributor/migrate/up`, {
					headers: getHeaders({ rootState })
				})
			return data
		},
		async resetOrderProducts( {rootState}, distributorID ){
			const { data } = await axios
				.get(
					`${envs.API_URL}order/reset_order_product_headers/${distributorID}`,
					{
						headers: getHeaders({ rootState })
					}
				)
			return true
		},
		async resetInventory( {rootState}, distributorID ){
			const { data } = await axios
				.get(
					`${envs.API_URL}order/reset_inventory_headers/${distributorID}`,
					{
						headers: getHeaders({ rootState })
					}
				)
			return true
		},
		async resetPOS( {rootState}, distributorID ){
			const { data } = await axios
				.get(
					`${envs.API_URL}pos/reset_headers/${distributorID}`,
					{
						headers: getHeaders({ rootState })
					}
				)
			return true
		}
	}
}
