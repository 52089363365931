export const SETTINGS_ROUTES = {
  SETTINGS: "settings",
  BOX: "boxSettings",
  PAYMENT_CREATE: "settings-payment-methods-create",
  PAYMENT_LIST: "settings-payment-methods-list",
  PAYMENT_EDIT: "settings-payment-methods-edit",
  INTERVALS_LIST: "settings-demo-intervals-list",
  INTERVALS_CREATE: "settings-demo-intervals-create",
  INTERVALS_EDIT: "settings-demo-intervals-edit",
  DISTRIBUTORS_MIGRATE: "settings-distributors-migrate",
  FILE_HEADERS: "settings-file-headers",
  JOB_ROLES_LIST: "settings-job-roles-list",
  JOB_ROLES_CREATE: "settings-job-roles-create",
  JOB_ROLES_EDIT: "settings-job-roles-edit",
  COUNTRY_LIST: "settings-country-list",
  COUNTRY_CREATE: "settings-country-create",
  COUNTRY_EDIT: "settings-country-edit",
  EMAILS: "setting-emails",
  VERTICALS_REL_INDEX: "settings-vertical-activity-rel",
  VERTICALS_REL_UPLOAD: "settings-vertical-activity-rel-upload",
  VERTICALS_REL_WORDS_LIST: "settings-vertical-activity-rel-word-list",
  VERTICALS_REL_WORDS_CREATE: "settings-vertical-activity-rel-word-create",
  VERTICALS_REL_WORDS_EDIT: "settings-vertical-activity-rel-word-edit",
  VERTICALS_REL_TERMS: "settings-vertical-activity-rel-term-list",
  VERTICALS_REL_TERM_EDIT: "settings-vertical-activity-rel-term-edit",
  VERTICALS_REL_MISSING_EDIT: "settings-vertical-activity-rel-missing-edit",
}

export default [
  {
    path: "/settings",
    name: SETTINGS_ROUTES.SETTINGS,
    component: function () {
      return import(
        /* webpackChunkName: "settings" */
        "../views/settings/Index.vue"
      );
    },
  },
  {
    path: "/settings/box",
    name: SETTINGS_ROUTES.BOX,
    component: function () {
      return import(
        /* webpackChunkName: "settings" */
        "../views/settings/BoxCallback.vue"
      );
    },
  },
  {
    path: "/settings/payment_methods/list",
    name: SETTINGS_ROUTES.PAYMENT_LIST,
    component: function () {
      return import(
        /* webpackChunkName: "settings-payments-methods-list" */
        "../views/settings/paymentMethods/List.vue"
      );
    },
  },
  {
    path: "/settings/payment_methods/create",
    name: SETTINGS_ROUTES.PAYMENT_CREATE,
    component: function () {
      return import(
        /* webpackChunkName: "settings-payments-methods-create" */
        "../views/settings/paymentMethods/CreateEdit.vue"
      );
    },
  },
  {
    path: "/settings/payment_methods/edit/:id",
    name: SETTINGS_ROUTES.PAYMENT_EDIT,
    component: function () {
      return import(
        /* webpackChunkName: "settings-payments-methods-edit" */
        "../views/settings/paymentMethods/CreateEdit.vue"
      );
    },
  },
  {
    path: "/settings/demo_intervals",
    name: SETTINGS_ROUTES.INTERVALS_LIST,
    component: function () {
      return import(
        /* webpackChunkName: "settings-demo-intervals-list" */
        "../views/settings/demoIntervals/List.vue"
      );
    },
  },
  {
    path: "/settings/demo_intervals/create",
    name: SETTINGS_ROUTES.INTERVALS_CREATE,
    component: function () {
      return import(
        /* webpackChunkName: "settings-demo-intervals-create" */
        "../views/settings/demoIntervals/CreateEdit.vue"
      );
    },
  },
  {
    path: "/settings/demo_intervals/edit/:id",
    name: SETTINGS_ROUTES.INTERVALS_EDIT,
    component: function () {
      return import(
        /* webpackChunkName: "settings-demo-intervals-edit" */
        "../views/settings/demoIntervals/CreateEdit.vue"
      );
    },
  },
  {
    path: "/settings/distributors_migrate",
    name: SETTINGS_ROUTES.DISTRIBUTORS_MIGRATE,
    component: function () {
      return import(
        /* webpackChunkName: "settings-distributors-migrate" */
        "../views/settings/DistributorsMigrate.vue"
      );
    },
  },
  {
    path: "/settings/file_headers",
    name: SETTINGS_ROUTES.FILE_HEADERS,
    component: function () {
      return import(
        /* webpackChunkName: "settings-file-headers" */
        "../views/settings/fileHeaders/List.vue"
      );
    },
  },
  // JOB ROLES
  {
    path: "/settings/job_roles",
    name: SETTINGS_ROUTES.JOB_ROLES_LIST,
    component: function () {
      return import(
        /* webpackChunkName: "settings-job-roles-list" */
        "../views/settings/jobRoles/List.vue"
      );
    },
  },
  {
    path: "/settings/job_roles/create",
    name: SETTINGS_ROUTES.JOB_ROLES_CREATE,
    component: function () {
      return import(
        /* webpackChunkName: "settings-job-roles-create" */
        "../views/settings/jobRoles/CreateEdit.vue"
      );
    },
  },
  {
    path: "/settings/job_roles/edit/:id",
    name: SETTINGS_ROUTES.JOB_ROLES_EDIT,
    component: function () {
      return import(
        /* webpackChunkName: "settings-job-roles-edit" */
        "../views/settings/jobRoles/CreateEdit.vue"
      );
    },
  },
  // COUNTRY
  {
    path: "/settings/country",
    name: SETTINGS_ROUTES.COUNTRY_LIST,
    component: function () {
      return import(
        /* webpackChunkName: "settings-country-list" */
        "../views/settings/country/List.vue"
      );
    },
  },
  {
    path: "/settings/country/create",
    name: SETTINGS_ROUTES.COUNTRY_CREATE,
    component: function () {
      return import(
        /* webpackChunkName: "settings-country-create" */
        "../views/settings/country/CreateEdit.vue"
      );
    },
  },
  {
    path: "/settings/country/edit/:id",
    name: SETTINGS_ROUTES.COUNTRY_EDIT,
    component: function () {
      return import(
        /* webpackChunkName: "settings-country-edit" */
        "../views/settings/country/CreateEdit.vue"
      );
    },
  },
  {
    path: "/settings/emails/:name",
    name: SETTINGS_ROUTES.EMAILS,
    component: function () {
      return import(
        /* webpackChunkName: "settings-suport-email-list" */
        "../views/settings/Emails.vue"
      );
    },
  },
  {
    path: "/settings/va_rel",
    name: SETTINGS_ROUTES.VERTICALS_REL_INDEX,
    component: function () {
      return import(
        /* webpackChunkName: "settings-vertical-activity-rel" */
        "../views/settings/verticalActivityRel/Index.vue"
      );
    },
  },
  {
    path: "/settings/va_rel/upload",
    name: SETTINGS_ROUTES.VERTICALS_REL_UPLOAD,
    component: function () {
      return import(
        /* webpackChunkName: "settings-vertical-activity-rel-upload" */
        "../views/settings/verticalActivityRel/Upload.vue"
      );
    },
  },
  {
    path: "/settings/va_rel/words/list",
    name: SETTINGS_ROUTES.VERTICALS_REL_WORDS_LIST,
    component: function () {
      return import(
        /* webpackChunkName: "settings-vertical-activity-rel-words-list" */
        "../views/settings/verticalActivityRel/Words.vue"
      );
    },
  },
  {
    path: "/settings/va_rel/words/create",
    name: SETTINGS_ROUTES.VERTICALS_REL_WORDS_CREATE,
    component: function () {
      return import(
        /* webpackChunkName: "settings-vertical-activity-rel-words-create" */
        "../views/settings/verticalActivityRel/WordCreateEdit.vue"
      );
    },
  },
  {
    path: "/settings/va_rel/words/edit/:id",
    name: SETTINGS_ROUTES.VERTICALS_REL_WORDS_EDIT,
    component: function () {
      return import(
        /* webpackChunkName: "settings-vertical-activity-rel-words-edit" */
        "../views/settings/verticalActivityRel/WordCreateEdit.vue"
      );
    },
  },
  {
    path: "/settings/va_rel/term/edit/:id",
    name: SETTINGS_ROUTES.VERTICALS_REL_TERM_EDIT,
    component: function () {
      return import(
        /* webpackChunkName: "settings-vertical-activity-rel-term-edit" */
        "../views/settings/verticalActivityRel/TermCreateEdit.vue"
      );
    },
  },
  {
    path: "/settings/va_rel/missing/edit/:id",
    name: SETTINGS_ROUTES.VERTICALS_REL_MISSING_EDIT,
    component: function () {
      return import(
        /* webpackChunkName: "settings-vertical-activity-rel-missing-edit" */
        "../views/settings/verticalActivityRel/TermCreateEdit.vue"
      );
    },
  },
  {
    path: "/settings/va_rel/all_terms",
    name: SETTINGS_ROUTES.VERTICALS_REL_TERMS,
    component: function () {
      return import(
        /* webpackChunkName: "settings-vertical-activity-rel-term-list" */
        "../views/settings/verticalActivityRel/Terms.vue"
      );
    },
  },
].map((item) => {
  item.meta = { tab: "settings" };
  return item;
});
