export default [
	{
		path: '/contact',
		name: 'contact-list',
		component: function () {
			return import(/* webpackChunkName: "contact-list" */ '../views/contact/List.vue')
		}
	},
	{
		path: '/contact/ticket/create',
		name: 'contact-create',
		component: function () {
			return import(/* webpackChunkName: "contact-createEdit" */ '../views/contact/CreateEdit.vue')
		}
	},
	{
		path: '/contact/ticket/view/:id',
		name: 'contact-view',
		component: function () {
			return import(/* webpackChunkName: "contact-view" */ '../views/contact/View.vue')
		}
	},
].map( item => {
	item.meta = { tab: 'contact' }
	return item
})