export default [
	{
		path: '/notifications',
		name: 'notifications',
		component: function () {
			return import(/* webpackChunkName: "notifications" */ '../views/notifications/Index.vue')
		}
	},
].map( item => {
	item.meta = { tab: 'notifications' }
	return item
})