import axios from "axios";
import envs from "../../env";
import NumberFormarter from "../helpers/number_formater";
import { getHeaders } from "../helpers/store_utils";

const buildView = () => ({
	distributorData: {},
	distributorID: null,
	file: null,
	config: {
		headers: {
			date: null,
			partNumber: null,
			quantity: null,
			price: null,
		},
		currencyPattern: "brl",
		missingData: null,
		missingTotals: {},
	},
	products: [],
	totals: null,
	total: null,
	data: {},
	replaceAction: null,
	replaceList: [],
	extras: {
		missingDates: false,
		missingPrices: false,
		multipleMonths: false,
	},
});

export default {
	namespaced: true,
	state: () => ({
		list: [],
		listArgs: {
			date: {
				month: 0,
				year: 0,
			},
			distributor: 0,
		},
		view: buildView(),
	}),
	mutations: {
		updateDate(state, value) {
			state.view.config.headers.date = value;
		},
		updatePartNumber(state, value) {
			state.view.config.headers.partNumber = value;
		},
		updateQuantity(state, value) {
			state.view.config.headers.quantity = value;
		},
		updatePrice(state, value) {
			state.view.config.headers.price = value;
		},
		updatePattern(state, value) {
			state.view.config.currencyPattern = value;
		},
		updateTotal(state, value) {
			state.view.total = value;
		},
		updateReplaceAction(state, value) {
			state.view.replaceAction = value;
		},
		updateMissingData(state, value) {
			state.view.config.missingData = value;
		},
		resetViewValues(state) {
			state.view = {
				...buildView(),
				distributorID: state.view.distributorID,
			};
		},
	},
	actions: {
		adjustMissingData({ state }) {
			if (state.view.extras.missingDates && state.view.extras.missingPrices) {
				var retTotals = {},
					prop;
				state.view.config.missingData.forEach((item) => {
					var [year, month] = item.data.date.split("-");
					prop = `${month}${year}`;
					retTotals[prop] = parseFloat(
						item.data.value.replace(/[^0-9,]/g, "").replace(",", ".")
					);
				});
				state.view.totals = retTotals;
			} else if (state.view.extras.missingPrices) {
				var retTotals = {};
				const totals = state.view.config.missingTotals;
				for (var prop in totals) {
					retTotals[prop] = NumberFormarter.clear(totals[prop]);
					if (retTotals[prop] == "0" || !retTotals[prop]) {
						return false;
					}
				}

				if (
					Object.keys(state.view.totals).length != Object.keys(retTotals).length
				) {
					return false;
				}

				state.view.totals = retTotals;
			}

			return true;
		},
		async get({ state, rootState }, id) {
			const { data } = await axios.get(`${envs.API_URL}inventory/get/${id}`, {
				headers: getHeaders({ rootState }),
			});
			// console.log( data )
			// state.list = data.body
			state.view.products = data.body.products.map((item) => {
				item["partNumber"] = item.part_number;
				return item;
			});
			state.view.total = data.body.log.total;
			state.view.distributorData = data.body.distributor;
			return true;
		},
		async saveRow({ state, rootState }, payload) {
      const { data } = await axios.post(
        `${envs.API_URL}inventory/save-row`,
        payload,
        {
          headers: getHeaders({ rootState }),
        }
      );
      console.log(data);
      return true;
    },
		async history({ state, rootState }, id) {
      const { data } = await axios.get(`${envs.API_URL}inventory/history/${id}`, {
        headers: getHeaders({ rootState }),
      });
      return data.body;
    },
		async list({ state, rootState }) {
			const { data } = await axios.get(`${envs.API_URL}inventory/list`, {
				headers: getHeaders({ rootState }),
				params: {
					month: state.listArgs.date.month,
					year: state.listArgs.date.year,
					distributor: state.listArgs.distributor,
				},
			});
			state.list = data.body;
			return true;
		},
		async checkHeadersConfig({ state, rootState }) {
			const { data } = await axios.get(
				`${envs.API_URL}inventory/check_headers_config/${state.view.distributorID}`,
				{
					headers: getHeaders({ rootState }),
				}
			);

			if (data.status) {
				return data.body;
			} else {
				return false;
			}
		},
		async readFileHeaders({ state, rootState }) {
			var formData = new FormData();
			formData.append("files", state.view.file, state.view.file.name);

			const { data } = await axios.post(
				`${envs.API_URL}inventory/read_file_headers`,
				formData,
				{
					headers: getHeaders({ rootState }),
				}
			);

			if (data.status) {
				return data.body.map((item) => (item || "").trim());
			} else {
				return false;
			}
		},
		async dataProccess({ state, rootState }, payload) {
			var formData = new FormData();
			try {
				formData.append("files", state.view.file, state.view.file.name);
				formData.append("distributor_id", state.view.distributorID);
				formData.append("date", payload.date);
			} catch (e) {
				return {
					status: false,
					message: "NO_FILE",
				};
			}

			const { data } = await axios.post(
				`${envs.API_URL}inventory/data_proccess`,
				formData,
				{
					headers: getHeaders({ rootState }),
				}
			);

			if (!data.status) {
				if (data.message == "INSUFFICIENT_NUMBER_OF_COLUMNS") {
					return {
						status: false,
						message: data.message,
					};
				} else if (data.message == "REQUIRED_HEADERS_NOT_FOUND") {
					return {
						status: false,
						message: data.message,
					};
				} else if (data.message == "DATA_FAIL") {
					return {
						status: false,
						message: data.message,
						errors: data.body,
					};
				}
			}
			state.view.products = data.body.products.map((item, index) => {
				item["id"] = index;
				return item;
			});
			state.view.totals = data.body.totals;
			state.view.extras = data.body.extras;

			return {
				status: true,
			};
		},
		async checkPreviousData({ state, rootState }) {
			const { data } = await axios.post(
				`${envs.API_URL}inventory/check_previous_data`,
				{
					products: state.view.products,
					distributorID: state.view.distributorID,
				},
				{
					headers: getHeaders({ rootState }),
				}
			);

			return data;
		},
		async setHeadersConfig({ state, rootState }) {
			const { data } = await axios.post(
				`${envs.API_URL}inventory/set_headers_config`,
				{
					config: state.view.config,
					distributorID: state.view.distributorID,
				},
				{
					headers: getHeaders({ rootState }),
				}
			);

			return true;
		},
		async save({ state, rootState, commit }, replace) {
			const { data } = await axios.post(
				`${envs.API_URL}inventory/save`,
				{
					products: state.view.products,
					distributorID: state.view.distributorID,
					totals: state.view.totals,
					file: state.view.file.name,
					replace,
				},
				{
					headers: getHeaders({ rootState }),
				}
			);

			return data;
		},
		async monthsHistory({ state, rootState }) {
			const { data } = await axios.get(
				`${envs.API_URL}inventory/months_history/${state.view.distributorID}`,
				{
					headers: getHeaders({ rootState }),
				}
			);

			return data.body;
		},
		async remove_data({ state, rootState }, ID) {
			const { data } = await axios.get(
				`${envs.API_URL}inventory/remove_data/${ID}`,
				{
					headers: getHeaders({ rootState }),
				}
			);

			return data.body;
		},
	},
};
