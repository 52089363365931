<template>
    <div>
        <trumbowyg
            v-model="content"
            :config="config" />
    </div>
</template>

<script>
import Trumbowyg from 'vue-trumbowyg'
import 'trumbowyg/dist/ui/trumbowyg.css'

export default {
    props: ['value'],
    data: () => ({
        content: '',
        config: {
                btns: [
                    ['formatting'],
                    ['strong', 'em', 'superscript', 'subscript'],
                    ['link'],
                    ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
                    ['unorderedList', 'orderedList'],
                    ['horizontalRule'],
                    ['removeformat'],
                    ['fullscreen'],
                    ['btnGrp-image'],
                    ['viewHTML'],
                ],
                semantic: {
                   'div': 'div' // Editor does nothing on div tags now
                },
                resetCss: true
            }
    }),
    components: {
        Trumbowyg
    },
    watch: {
        content: function(){
            this.value = this.content;
            this.$emit('input', this.value);
        }
    },
    created(){
        this.content = this.value
    },
    methods: {
        update: function()
        {
            this.$forceUpdate()
        }
    }
}
</script>