<template>
    <div class="f-checkbox-title">
        <div class="f-checkbox-title--checkbox">
            <v-switch
                :disabled="false"
                hide-details
                v-model="_value"
                @change="changeValue">
            </v-switch>
        </div>
        <div class="f-checkbox-title--title">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    props: [ 'value' ],
    data: () => ({
        _value: null
    }),
    created(){
        this._value = this.value
    },
    watch: {
        value() {
            this._value = this.value
        }
    },
    methods: {
        changeValue( val ) {
            this.$emit( 'input', val )
        }
    }
}
</script>