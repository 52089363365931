import Vue from "vue";
import Vuex from "vuex";

import alert from "./alert";
import auth from "./auth";
import contact from "./contact";
import country from "./country";
import demoIntervals from "./demoIntervals";
import distributor from "./distributor";
import dialog from "./dialog";
import inventory from "./inventory";
import jobRoles from "./jobRoles";
import languages from "./languages";
import mkt from "./mkt";
import mktItems from "./mktItems";
import order from "./order";
import paymentMethods from "./paymentMethods";
import pos from "./pos";
import profile from "./profile";
import snackbar from "./snackbar";
import system from "./system";
import tags from "./tags";
import user from "./user";
import box from "./box";
import option from "./option";
import verticals from "./verticals";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    alert,
    auth,
    contact,
    country,
    demoIntervals,
    distributor,
    dialog,
    inventory,
    jobRoles,
    languages,
    mkt,
    mktItems,
    order,
    pos,
    profile,
    paymentMethods,
    snackbar,
    system,
    tags,
    user,
    box,
    option,
    verticals,
  },
});
