export default [
	{
		path: '/users',
		name: 'user-list',
		component: function () {
			return import(/* webpackChunkName: "user-list" */ '../views/users/List.vue')
		}
	},
	{
		path: '/user/create',
		name: 'user-create',
		component: function () {
			return import(/* webpackChunkName: "user-create" */ '../views/users/CreateEdit.vue')
		}
	},
	{
		path: '/user/edit/:id',
		name: 'user-edit',
		component: function () {
			return import(/* webpackChunkName: "user-edit" */ '../views/users/CreateEdit.vue')
		}
	},
].map( item => {
	item.meta = { tab: 'users' }
	return item
})