export default [
	{
		path: '/inventory',
		name: 'inventory-list',
		component: function () {
			return import(/* webpackChunkName: "inventory-list" */ '../views/inventory/List.vue')
		}
	},
	{
		path: '/inventory/upload',
		name: 'inventory-upload',
		component: function () {
			return import(/* webpackChunkName: "inventory-upload" */ '../views/inventory/FileInserter.vue')
		}
	},
	{
		path: '/inventory/upload/process',
		name: 'inventory-upload-process',
		component: function () {
			return import(/* webpackChunkName: "inventory-upload-process" */ '../views/inventory/FileInserter.vue')
		}
	},
	{
		path: '/inventory/preview',
		name: 'inventory-preview',
		component: function () {
			return import(/* webpackChunkName: "inventory-preview" */ '../views/inventory/Preview.vue')
		}
	},
	{
		path: '/inventory/view/:id',
		name: 'inventory-view',
		component: function () {
			return import(/* webpackChunkName: "inventory-view" */ '../views/inventory/View.vue')
		}
	},
	{
		path: '/inventory/set_config',
		name: 'inventory-set-config',
		component: function () {
			return import(/* webpackChunkName: "inventory-set-config" */ '../views/inventory/SetDefaults.vue')
		}
	},
	{
		path: '/inventory/missing_data',
		name: 'inventory-missing-data',
		component: function () {
			return import(/* webpackChunkName: "inventory-missing-data" */ '../views/inventory/MissingData.vue')
		}
	}
].map( item => {
	item.meta = { tab: 'inventory' }
	return item
})