import axios from "axios";
import envs from "../../env";
import dateFormater from "../helpers/date_formater";
import { getHeaders } from "../helpers/store_utils";

export default {
  namespaced: true,
  state: () => ({
    list: [],
    sending: false,
    view: {
      permissions: {},
      locale: localStorage.getItem("currentLang") || "pt",
    },
  }),
  getters: {
    getSending({ sending }) {
      return sending;
    },
  },
  mutations: {
    setView(state, view) {
      state.view = view;
    },
    setSending(state, flag) {
      state.sending = flag;
    },
  },
  actions: {
    async resetPassword({ rootState }, userID) {
      const { data } = await axios.get(
        `${envs.API_URL}user/resetpassword/${userID}`,
        {
          headers: getHeaders({ rootState }),
        }
      );
      return true;
    },
    async get({ state, rootState }, userID) {
      const { data } = await axios.get(`${envs.API_URL}user/get/${userID}`, {
        headers: getHeaders({ rootState }),
      });

      var permissions = [];
      const parsedUserPermissions = JSON.parse(data.body.permissions);
      rootState.auth.permissions.forEach((item) => {
        var permission = parsedUserPermissions.find(
          (it) => it.route == item.route
        );
        if (permission !== undefined) {
          permissions.push({
            title: item.title,
            route: item.route,
            read: permission.read,
            write: permission.write,
          });
        } else {
          permissions.push({
            title: item.title,
            route: item.route,
            read: false,
            write: false,
          });
        }
      });

      state.view = {
        id: data.body.id,
        name: data.body.name,
        email: data.body.email,
        permissions: permissions,
        job_role_id: data.body.job_role_id,
        distributor: data.body.distributor_id,
        lang: data.body.lang,
      };

      return true;
    },
    async edit({ state, rootState }) {
      const { data } = await axios.post(
        `${envs.API_URL}user/edit`,
        state.view,
        {
          headers: getHeaders({ rootState }),
        }
      );

      return true;
    },
    async remove({ state, rootState }) {
      const { data } = await axios.get(
        `${envs.API_URL}user/remove/${state.view.id}`,
        {
          headers: getHeaders({ rootState }),
        }
      );

      return true;
    },
    async list({ state, rootState }) {
      const { data } = await axios.get(`${envs.API_URL}user/list`, {
        headers: getHeaders({ rootState }),
      });

      state.list = dateFormater.multiline(data.body);

      return true;
    },
    async create({ commit, state, rootState }) {
      console.log(state.view);
      commit("setSending", true);
      const { data } = await axios.post(
        `${envs.API_URL}user/create`,
        state.view,
        {
          headers: getHeaders({ rootState }),
        }
      );
      commit("setSending", false);
      return data.status;
    },
    clear({ state }, type) {
      switch (type) {
        case "list":
          state.list = [];
          break;
        case "view":
          state.view = {
            permissions: {},
          };
          break;
        default:
          break;
      }
    },
  },
};
