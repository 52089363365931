import axios from 'axios'
import envs from '../../env'
import dateFormater from '../helpers/date_formater'
import { getHeaders } from '../helpers/store_utils'

export default {
	namespaced: true,
	state: () => ({
		list: [],
		view: {
			baseData: {
				types: []
			},
			type: null
		}
	}),
	getters: {},
	mutations: {
		setViewType( state, value ){
			state.view.type = value
		}
	},
	actions: {
		//items
		async items_list({ state, rootState }) {
			const { data } = await axios
				.get( `${envs.API_URL}mkt/items_list`, {
					headers: getHeaders({ rootState })
				})
			state.list = dateFormater.multiline( data.body )

			return true
		},
		async items_listTypes({ state, rootState }) {
			const { data } = await axios
				.get( `${envs.API_URL}mkt/items_list_types`, {
					headers: getHeaders({ rootState })
				})
			state.view.baseData.types = data.body

			return true
		},
		//contact
		async contact_list({ state, rootState }) {
			const { data } = await axios
				.get( `${envs.API_URL}mkt/contact_list`, {
					headers: getHeaders({ rootState })
				})
			state.list = dateFormater.multiline( data.body )

			return true
		},
		async contact_get({ state, rootState }, contactID ) {
			const { data } = await axios
				.get( `${envs.API_URL}mkt/contact_get/${contactID}`, {
					headers: getHeaders({ rootState })
				})
			// state.list = dateFormater.multiline( data.body )

			return dateFormater.single( data.body )
		},
		async contact_save({ state, rootState }, contact ) {
			const { data } = await axios
				.post(
					`${envs.API_URL}mkt/contact_save`,
					contact,
					{
						headers: getHeaders({ rootState })
					}
				)
			return true
		},
		//newsletter
		async newsletter_list({ state, rootState }) {
			const { data } = await axios
				.get( `${envs.API_URL}mkt/newsletter_list`, {
					headers: getHeaders({ rootState })
				})
			state.list = dateFormater.multiline(
				data.body
			).map(item => {
				item.active = item.active?'sim':'não'
				return item
			})

			return true
		},
		async newsletter_get({ rootState }, newsletterID ) {
			const { data } = await axios
				.get( `${envs.API_URL}mkt/newsletter_get/${newsletterID}`, {
					headers: getHeaders({ rootState })
				})

			return data.body
		},
		async newsletter_save({ rootState }, newsletter ) {
			const { data } = await axios
				.post(
					`${envs.API_URL}mkt/newsletter_save`,
					newsletter,
					{
						headers: getHeaders({ rootState })
					}
				)
			return true
		},
		clear({state}, type){
			switch (type) {
				case 'list':
					state.list = []
					break
				case 'view':
					state.view = {}
					break
				default:
					break
			}
		}
	}
}
