<template>
	<div>
		<div v-if="loaded">
			<f-default-header title="Marketing" subtitle="Home Portal" />
			<div style="text-align: center">
				<v-btn elevation="0" color="primary" @click="go">Ir para portal</v-btn>
			</div>
		</div>
	</div>
</template>

<script>
import env from "../../env";
export default {
	data() {
		return {
			loaded: false,
		};
	},
	created() {
		const { permissions } = this.$store.state.auth.user;

		const loginOrder = [
			"orders",
			"inventory",
			"pos",
			"mkt",
			"distributors",
			"contact",
			"settings",
		];

		var targetRoute = null;
		try {
			for (var i = 0; i < loginOrder.length; i++) {
				const item = loginOrder[i];
				console.log(item);
				const current = permissions.find(
					(route) => route.route === item && route.read
				);
				if (current) {
					targetRoute = current.route;
					break;
				}
			}

			if (!targetRoute) {
				throw new Error("Permission denied");
			}
		} catch (e) {
			console.log(e);
		}

		if (targetRoute) {
			this.$router.push(targetRoute);
		}

		this.loaded = true;
	},
	methods: {
		go() {
			window.open(env.MKT_URL, "_blank");
		},
	},
};
</script>
