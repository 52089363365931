<template>
    <div id="expandable-div">
        <transition
            name="expand"
            @enter="enter"
            @after-enter="afterEnter"
            @leave="leave">

            <div v-if="expanded">
                <div class="divContent">
                    <slot />
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    props: [ 'expanded' ],
    methods: {
        enter(element) {
            const width = getComputedStyle(element).width

            element.style.width = width
            element.style.position = 'absolute'
            element.style.visibility = 'hidden'
            element.style.height = 'auto'

            const height = getComputedStyle(element).height

            element.style.width = null
            element.style.position = null
            element.style.visibility = null
            element.style.height = 0

            getComputedStyle(element).height

            requestAnimationFrame(() => {
                element.style.height = height
            })
        },
        afterEnter(element){
            element.style.height = 'auto'
        },
        leave(element){
            const height = getComputedStyle(element).height
            element.style.height = height
            getComputedStyle(element).height
            requestAnimationFrame(() => {
                element.style.height = 0
            })
        },
    },
}
</script>
