import axios from "axios";
import envs from "../../env";
import dateFormater from "../helpers/date_formater";
import { getHeaders } from "../helpers/store_utils";

export default {
	namespaced: true,
	state: () => ({
		list: [],
		view: {},
	}),
	getters: {},
	mutations: {},
	actions: {
		async config({ rootState }, distributorID) {
			var distributor = {};

			const distributorResult = await axios.get(
				`${envs.API_URL}distributor/config/${distributorID}`,
				{
					headers: getHeaders({ rootState }),
				}
			);
			distributor = distributorResult.data.body;

			return distributor;
		},
		async configSave({ rootState }, config) {
			// var distributor = {}
			await axios.post(
				`${envs.API_URL}distributor/config/set`,
				{
					distributorID: rootState.order.view.distributor_id,
					config: {
						header: "xlsHeaders",
						data: config,
					},
				},
				{
					headers: getHeaders({ rootState }),
				}
			);
			// distributor = distributorResult.data.body

			return true;
		},
		async getClean({ rootState }, distributorID) {
			const { data } = await axios.get(
				`${envs.API_URL}distributor/get/${distributorID}`,
				{
					headers: getHeaders({ rootState }),
				}
			);
			return data;
		},
		async refresh({ dispatch, state }) {
			let distributorID;

			try {
				distributorID = state.view.id;
			} catch (e) {
				console.log("no distributorID");
				return;
			}

			if (!distributorID) {
				console.log("no distributorID in state");
				return;
			}

			await dispatch("get", distributorID);
		},
		async get({ state, rootState }, distributorID) {
			var distributor = {};

			const distributorResult = await axios.get(
				`${envs.API_URL}distributor/get/${distributorID}`,
				{
					headers: getHeaders({ rootState }),
				}
			);
			distributor = distributorResult.data.body;

			const contractsResult = await axios.get(
				`${envs.API_URL}distributor/get_contracts/${distributorID}`,
				{
					headers: getHeaders({ rootState }),
				}
			);
			distributor.contracts = contractsResult.data.body.map((item) => {
				item["action"] = "none";
				return item;
			});

			const CNPJResult = await axios.get(
				`${envs.API_URL}distributor/get_cnpjs/${distributorID}`,
				{
					headers: getHeaders({ rootState }),
				}
			);
			distributor.cnpjs = CNPJResult.data.body.map((item) => {
				item["action"] = "none";
				return item;
			});

			state.view = distributor;
			try {
				state.view.parsedConfig = JSON.parse(state.view.config);
			} catch (e) {}

			if (!state.view.countries) {
				state.view.countries = [];
			}

			return true;
		},
		async list({ state, rootState }) {
			const { data } = await axios.get(`${envs.API_URL}distributor/list`, {
				headers: getHeaders({ rootState }),
			});

			state.list = dateFormater.multiline(data.body);

			return state.list;
		},
		async listContracts({ state, rootState }, distributorID) {
			const { data } = await axios.get(
				`${envs.API_URL}distributor/get_contracts/${distributorID}`,
				{
					headers: getHeaders({ rootState }),
				}
			);

			return data.body;
		},
		async listCNPJs({ state, rootState }, distributorID) {
			const { data } = await axios.get(
				`${envs.API_URL}distributor/get_cnpjs/${distributorID}`,
				{
					headers: getHeaders({ rootState }),
				}
			);

			return data.body;
		},
		async getCNPJ({ rootState }, cnpjID) {
			const { data } = await axios.get(
				`${envs.API_URL}distributor/get_cnpj/${cnpjID}`,
				{
					headers: getHeaders({ rootState }),
				}
			);

			return data.body;
		},
		async create({ state, rootState }) {
			const distributor = state.view;
			distributor.countries = rootState.country.selected;

			const { data } = await axios.post(
				`${envs.API_URL}distributor/create`,
				distributor,
				{
					headers: getHeaders({ rootState }),
				}
			);
			return data;
		},
		async edit({ state, rootState }) {
			const distributor = state.view;
			distributor.countries = rootState.country.selected;

			const { data } = await axios.post(
				`${envs.API_URL}distributor/edit`,
				distributor,
				{
					headers: getHeaders({ rootState }),
				}
			);
			return data;
		},
		async remove({ state, rootState }) {
			const { data } = await axios.get(
				`${envs.API_URL}distributor/remove/${state.view.id}`,
				{
					headers: getHeaders({ rootState }),
				}
			);

			return true;
		},
		async removeContract({ state, rootState }, contractID) {
			const { data } = await axios.get(
				`${envs.API_URL}distributor/remove_contract/${contractID}`,
				{
					headers: getHeaders({ rootState }),
				}
			);

			return true;
		},
		async removeCNPJ({ state, rootState }, cnpjID) {
			const { data } = await axios.get(
				`${envs.API_URL}distributor/remove_cnpj/${cnpjID}`,
				{
					headers: getHeaders({ rootState }),
				}
			);

			return true;
		},
		clear({ state }, type) {
			switch (type) {
				case "list":
					state.list = [];
					break;
				case "view":
					state.view = {};
					break;
				default:
					break;
			}
		},
	},
};
