import axios from 'axios'
import envs from '../../env'
import dateFormater from '../helpers/date_formater'
import { getHeaders } from '../helpers/store_utils'

export default {
	namespaced: true,
	state: () => ({
		emails: [],
		list: [],
		view: {
			id: null,
			ticket: {},
			message: '',
			messages: []
		},
	}),
	mutations: {
		updateproviderId(state, value) {
			state.view.config.headers.providerId = value
		},
		updateEmails(state, value) {
			state.emails = value
		}
	},
	actions: {
		async send({ state, rootState }) {
			const { data } = await axios
				.post(
					`${envs.API_URL}contact/send`,
					{
						message: state.view.message,
						userID: rootState.auth.user.id
					},
					{
						headers: getHeaders({ rootState })
					}
				)
			return true
		},
		async respond({ state, rootState }) {
			const { data } = await axios
				.post(
					`${envs.API_URL}contact/respond`,
					{
						message: state.view.message,
						ticketID: state.view.id
					},
					{
						headers: getHeaders({ rootState })
					}
				)
			state.view.message = ''
			return true
		},
		async list({ state, rootState }) {
			const { data } = await axios
				.get(
					`${envs.API_URL}contact/list`,
					{
						headers: getHeaders({ rootState })
					}
				)
			state.list = dateFormater.fieldsWithTime(data.body, ['created_at'])
			return true
		},
		async get({ state, rootState }, id) {
			const { data } = await axios
				.get(`${envs.API_URL}contact/get/${id}`, {
					headers: getHeaders({ rootState })
				})

			state.view.id = id
			state.view.ticket = data.body.ticket
			state.view.messages = dateFormater.fieldsWithTime(data.body.interactions, ['created_at'])

			return true
		},
		async setStatus({ state, rootState }) {
			const { data } = await axios
				.post(
					`${envs.API_URL}contact/set_status`,
					{
						status: state.view.ticket.status,
						ticketID: state.view.ticket.id
					},
					{
						headers: getHeaders({ rootState })
					}
				)
		},
		async emails_list({ state, rootState }) {
			const { data } = await axios
				.get(`${envs.API_URL}contact/emails_list`,
					{
						headers: getHeaders({ rootState })
					})

			state.emails = data.body

			return true
		},
		async emails_save({ state, rootState }) {
			const { data } = await axios
				.post(
					`${envs.API_URL}contact/emails_save`,
					{
						emails: state.emails
					}
				)
			return true
		},
	}
}
