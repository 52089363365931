<template>
    <div id="loadingbar" v-if="loading" class="text-right">
        <v-progress-linear
            indeterminate
            height="12"
        ></v-progress-linear>
        <!-- <div class="message">{{message}}</div> -->
    </div>
</template>

<script>
export default {
    data () {
        return {
            show: true,
            message: ''
        }
    },
    computed: {
        loading() {
            return !this.$store.state.system.loading.done
        }
    }
    // created () {
    //     this.$store.subscribe((mutation, state) => {
    //         if (mutation.type === 'loadingbar/show') {
    //             this.message = state.loadingbar.message
    //             this.show = true
    //         } else if( mutation.type === 'loadingbar/hide' ) {
    //             this.show = false
    //         }
    //     })
    // }
}
</script>

<style lang="scss">
#loadingbar {
    position: fixed;
    top: 64px;
    right: 0;
    z-index: 1000;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    padding: 0;
    width: 100%;

    .message {
        text-transform: uppercase;
        padding-right: 10px;
        color: #bbbbbb;
        font-size: 10px;
    }
}
</style>