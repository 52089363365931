import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: colors.amber,
                secondary: colors.grey.darken1,
                accent: colors.shades.black,
                error: colors.red.accent3,
                warning: colors.red.accent3,
                info: colors.red.accent3,
                success: colors.red.accent3,
                anchor: '#8c9eff',
            }
        }
    }
})
