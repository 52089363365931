import axios from "axios";
import envs from "../../env";
import dateFormater from "../helpers/date_formater";
import { getHeaders } from "../helpers/store_utils";

export default {
  namespaced: true,
  state: () => ({
    list: [],
    view: {
      id: "",
      role: {
        pt: "",
        en: "",
        es: "",
      },
    },
  }),
  getters: {},
  mutations: {},
  actions: {
    async get({ state, rootState }, jobRoleID) {
      const { data } = await axios.get(
        `${envs.API_URL}job_roles/get/${jobRoleID}`,
        {
          headers: getHeaders({ rootState }),
        }
      );

      state.view = {
        id: data.body.id,
        role: {
          pt: JSON.parse(data.body.role).filter(item => item.language == 'pt')[0].role,
          en: JSON.parse(data.body.role).filter(item => item.language == 'en')[0].role,
          es: JSON.parse(data.body.role).filter(item => item.language == 'es')[0].role,
        },
      };
    },
    async edit({ state, rootState }) {
      let role = [];
      Object.keys(state.view.role).map((key) => {
        let payloadRole = {
          language: key,
          role: state.view.role[key],
        };
        role.push(payloadRole);
      });

      let payload = {
        id: state.view.id,
        role: JSON.stringify(role),
      };

      const { data } = await axios.post(
        `${envs.API_URL}job_roles/edit`,
        payload,
        {
          headers: getHeaders({ rootState }),
        }
      );

      return true;
    },
    async remove({ state, rootState }) {
      const { data } = await axios.get(
        `${envs.API_URL}job_roles/remove/${state.view.id}`,
        {
          headers: getHeaders({ rootState }),
        }
      );

      return true;
    },
    async list({ state, rootState }) {
      const { data } = await axios.get(`${envs.API_URL}job_roles/list`, {
        headers: getHeaders({ rootState }),
      });

      state.list = [];

      data.body.forEach((item) => {
        let role = JSON.parse(item.role);

        let roleTranslated = role.filter(
          (item) =>
            item.language === localStorage.getItem("currentLang").toLowerCase()
        );

        state.list.push({
          id: item.id,
          role: roleTranslated[0].role,
          created_at: dateFormater.fromDB(item.created_at),
        });
      });

      return state.list;
    },
    async create({ state, rootState }) {
      let role = [];
      Object.keys(state.view.role).map((key) => {
        let payloadRole = {
          language: key,
          role: state.view.role[key],
        };
        role.push(payloadRole);
      });

      let payload = {
        role: JSON.stringify(role),
      };

      const { data } = await axios.post(
        `${envs.API_URL}job_roles/create`,
        payload,
        {
          headers: getHeaders({ rootState }),
        }
      );
      return true;
    },
    clear({ state }, type) {
      switch (type) {
        case "list":
          state.list = [];
          break;
        case "view":
          state.view = {
            id: "",
            role: {
              pt: "",
              en: "",
              es: "",
            },
          };
          break;
        default:
          break;
      }
    },
  },
};
