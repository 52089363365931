export default [
	{
		path: '/privacy',
		name: 'privacy-index',
		component: function () {
			return import(/* webpackChunkName: "privacy-index" */ '../views/Privacy.vue')
		}
	}
].map( item => {
	item['meta'] = { layout: 'f-privacy' }
	return item
})