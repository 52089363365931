import axios from "axios";
import envs from "../../env";
import NumberFormarter from "../helpers/number_formater";
import DateFormater from "../helpers/date_formater";
import { getHeaders } from "../helpers/store_utils";

/**
 * @typedef {Object} ActionParams
 * @property {Object} state
 * @property {Object} rootState
 */

export default {
	namespaced: true,
	state: () => ({
		list: [],
		listArgs: {
			date: {
				month: 0,
				year: 0,
			},
			distributor: 0,
		},
		view: {
			distributorData: {},
			distributorID: null,
			file: null,
			config: {
				headers: {},
				currencyPattern: "brl",
				datePattern: "dmy",
				missingData: null,
				missingTotals: {},
			},
			products: [],
			totals: null,
			total: null,
			data: {},
			replaceAction: null,
			replaceList: [],
			extras: {
				missingDates: false,
				missingPrices: false,
				multipleMonths: false,
			},
		},
	}),
	mutations: {
		//headers:begin
		updateproviderId(state, value) {
			state.view.config.headers.providerId = value;
		},
		updateCity(state, value) {
			state.view.config.headers.city = value;
		},
		updateState(state, value) {
			state.view.config.headers.state = value;
		},
		updatePartNumber(state, value) {
			state.view.config.headers.partNumber = value;
		},
		updateQuantity(state, value) {
			state.view.config.headers.quantity = value;
		},
		updatePrice(state, value) {
			state.view.config.headers.price = value;
		},
		updateEndUser(state, value) {
			state.view.config.headers.endUser = value;
		},
		updatecnae(state, value) {
			state.view.config.headers.cnae = value;
		},
		updateDate(state, value) {
			state.view.config.headers.date = value;
		},
		updateDistributorName(state, value) {
			state.view.config.headers.distributorName = value;
		},
		updateDistributorCNPJ(state, value) {
			state.view.config.headers.distributorCnpj = value;
		},
		//headers:end
		updateCurrencyPattern(state, value) {
			state.view.config.currencyPattern = value;
		},
		updateDatePattern(state, value) {
			state.view.config.datePattern = value;
		},
		updateTotal(state, value) {
			state.view.total = value;
		},
		updateReplaceAction(state, value) {
			state.view.replaceAction = value;
		},
		updateMissingData(state, value) {
			state.view.config.missingData = value;
		},
	},
	actions: {
		/**
		 * @param {any} state
		 * @returns {Promise<boolean>}
		 */
		async adjustMissingData({ state }) {
			if (state.view.extras.missingDates && state.view.extras.missingPrices) {
				var retTotals = {},
					prop;
				state.view.config.missingData.forEach((item) => {
					var [year, month] = item.data.date.split("-");
					prop = `${month}${year}`;
					retTotals[prop] = parseFloat(
						item.data.value.replace(/[^0-9,]/g, "").replace(",", ".")
					);
				});
				state.view.totals = retTotals;
			} else if (state.view.extras.missingPrices) {
				var retTotals = {};
				const totals = state.view.config.missingTotals;
				for (var prop in totals) {

					retTotals[prop] = totals[prop].replace(/[^0-9,]/g, "").replace(",", ".")
					if (retTotals[prop] == "0" || !retTotals[prop]) {
						return false;
					}
				}

				if (
					Object.keys(state.view.totals).length != Object.keys(retTotals).length
				) {
					return false;
				}

				state.view.totals = retTotals;
			}

			return true;
		},
		async get({ state, rootState }, id) {
			const { data } = await axios.get(`${envs.API_URL}pos/get/${id}`, {
				headers: getHeaders({ rootState }),
			});
			// console.log( data )
			// state.list = data.body
			state.view.products = data.body.products.map((item) => {
				item["partNumber"] = item.part_number;
				return item;
			});

			state.view.total = data.body.log.total;
			state.view.distributorData = data.body.distributor;

			return true;
		},
		async saveRow({ rootState }, payload) {
			await axios.post(`${envs.API_URL}pos/save-row`, payload, {
				headers: getHeaders({ rootState }),
			});
			return true;
		},
		async history({ state, rootState }, id) {
			const { data } = await axios.get(`${envs.API_URL}pos/history/${id}`, {
				headers: getHeaders({ rootState }),
			});
			return data.body;
		},
		async list({ state, rootState }) {
			const { data } = await axios.get(`${envs.API_URL}pos/list`, {
				headers: getHeaders({ rootState }),
				params: {
					month: state.listArgs.date.month,
					year: state.listArgs.date.year,
					distributor: state.listArgs.distributor,
				},
			});
			state.list = data.body;
			return true;
		},
		async checkHeadersConfig({ state, rootState }) {
			const { data } = await axios.get(
				`${envs.API_URL}pos/check_headers_config/${state.view.distributorID}`,
				{
					headers: getHeaders({ rootState }),
				}
			);

			if (data.status) {
				return data.body;
			} else {
				return false;
			}
		},
		async readFileHeaders({ state, rootState }) {
			var formData = new FormData();
			formData.append("files", state.view.file, state.view.file.name);

			const { data } = await axios.post(
				`${envs.API_URL}pos/read_file_headers`,
				formData,
				{
					headers: getHeaders({ rootState }),
				}
			);

			if (data.status) {
				return data.body.map((item) => (item || "").trim());
			} else {
				return false;
			}
		},
		async dataProccess({ state, rootState }) {
			var formData = new FormData();
			try {
				formData.append("files", state.view.file, state.view.file.name);
				formData.append("distributor_id", state.view.distributorID);
			} catch (e) {
				return {
					status: false,
					message: "NO_FILE",
				};
			}

			const { data } = await axios.post(
				`${envs.API_URL}pos/data_proccess`,
				formData,
				{
					headers: getHeaders({ rootState }),
				}
			);

			if (!data.status) {
				if (data.message == "INSUFFICIENT_NUMBER_OF_COLUMNS") {
					return {
						status: false,
						message: data.message,
					};
				} else if (data.message == "REQUIRED_HEADERS_NOT_FOUND") {
					return {
						status: false,
						message: data.message,
					};
				} else if (data.message == "DATA_FAIL") {
					return {
						status: false,
						message: data.message,
						errors: data.body,
					};
				}
			}
			state.view.products = data.body.products.map((item, index) => {
				item["id"] = index;
				return item;
			});

			state.view.totals = data.body.totals;
			state.view.extras = data.body.extras;

			return {
				status: true,
			};
		},
		async checkPreviousData({ state, rootState }) {
			const { data } = await axios.post(
				`${envs.API_URL}pos/check_previous_data`,
				{
					products: state.view.products,
					distributorID: state.view.distributorID,
				},
				{
					headers: getHeaders({ rootState }),
				}
			);

			return data;
		},
		async setHeadersConfig({ state, rootState }) {
			const { data } = await axios.post(
				`${envs.API_URL}pos/set_headers_config`,
				{
					config: state.view.config,
					distributorID: state.view.distributorID,
				},
				{
					headers: getHeaders({ rootState }),
				}
			);

			return data;
		},
		/**
		 * @param {ActionParams} params
		 * @param {boolean} replace
		 * @returns {any}
		 */
		async save({ state, rootState }, replace) {
			const { data } = await axios.post(
				`${envs.API_URL}pos/save`,
				{
					products: state.view.products,
					distributorID: state.view.distributorID,
					totals: state.view.totals,
					file: state.view.file.name,
					replace,
				},
				{
					headers: getHeaders({ rootState }),
				}
			);

			return data;
		},
		async remove_data({ state, rootState }, payload) {
			const { data } = await axios.get(
				`${envs.API_URL}pos/remove_data/${payload.id}/${payload.region}`,
				{
					headers: getHeaders({ rootState }),
				}
			);

			return data.body;
		},
		async monthsHistory({ state, rootState }) {
			const { data } = await axios.get(
				`${envs.API_URL}pos/months_history/${state.view.distributorID}`,
				{
					headers: getHeaders({ rootState }),
				}
			);

			return data.body;
		},
		async noSellsReport({ state, rootState }, payload) {
			const { data } = await axios.get(
				`${envs.API_URL}pos/no_sells/${state.view.distributorID}`,
				{
					headers: getHeaders({ rootState }),
					params: payload,
				}
			);
			return data;
		},
	},
};
