import FDialog from '../components/GlobalDialog.vue'
import FSnackbar from '../components/Snackbar.vue'
import FAppBar from '../components/AppBar.vue'
import FMenu from '../components/Menu.vue'
import FAlerts from '../components/Alerts.vue'
import FDisabler from '../components/Disabler.vue'

export default {
	components: {
		FDialog,
		FSnackbar,
		FAppBar,
		FMenu,
		FAlerts,
		FDisabler,
	},
	// props: {
	// 	source: String,
	// },
	computed: {
		layout() {
			return (this.$route.meta.layout || 'default') + '-layout';
		},
	},
}