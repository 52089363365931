<template>
	<v-snackbar
		class="custom-snack"
		:color="color"
		timeout="6000"
		elevation="0"
		v-model="show" >
		
		<v-icon>{{ icon }}</v-icon>
		{{ message }}
		<template v-slot:action></template>
	</v-snackbar>
</template>

<script>
export default {
	data() {
		return {
			show: false,
			message: "",
			color: "",
			icon: "",
		};
	},
	created() {
		this.$store.subscribe((mutation, state) => {
			if (mutation.type === "snackbar/show") {
				this.message = state.snackbar.content;
				switch (state.snackbar.type) {
					case "error":
						this.color = "red";
						this.icon = "mdi-alert-circle-outline";
						break;
					case "success":
						this.color = "green";
						this.icon = "mdi-check-circle-outline";
						break;
					default:
						this.color = "orange";
						this.icon = "mdi-alert-outline";
				}
				this.show = true;
			}
		});
	},
};
</script>

<style lang="scss" scoped>
.custom-snack {
	i {
		margin-right: 10px;
	}
}
</style>
