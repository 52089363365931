export default [
  {
    path: "/pos",
    name: "pos-list",
    component: function () {
      return import(/* webpackChunkName: "pos-list" */ "../views/pos/List.vue");
    },
  },
  {
    path: "/pos/upload",
    name: "pos-upload",
    component: function () {
      return import(
        /* webpackChunkName: "pos-upload" */ "../views/pos/FileInserter.vue"
      );
    },
  },
  {
    path: "/pos/upload/process",
    name: "pos-upload-process",
    component: function () {
      return import(
        /* webpackChunkName: "pos-upload-process" */ "../views/pos/FileInserter.vue"
      );
    },
  },
  {
    path: "/pos/preview",
    name: "pos-preview",
    component: function () {
      return import(
        /* webpackChunkName: "pos-preview" */ "../views/pos/Preview.vue"
      );
    },
  },
  {
    path: "/pos/no_sells_report",
    name: "pos-nosells",
    component: function () {
      return import(
        /* webpackChunkName: "pos-nosells" */ "../views/pos/NoSellsReport.vue"
      );
    },
  },
  {
    path: "/pos/view/:id",
    name: "pos-view",
    component: function () {
      return import(/* webpackChunkName: "pos-view" */ "../views/pos/View.vue");
    },
  },
  {
    path: "/pos/set_config",
    name: "pos-set-config",
    component: function () {
      return import(
        /* webpackChunkName: "pos-set-config" */ "../views/pos/SetDefaults.vue"
      );
    },
  },
  {
    path: "/pos/missing_data",
    name: "pos-missing-data",
    component: function () {
      return import(
        /* webpackChunkName: "pos-missing-data" */ "../views/pos/MissingData.vue"
      );
    },
  },
].map((item) => {
  item.meta = { tab: "pos" };
  return item;
});
