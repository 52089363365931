export default [
	{
		path: '/profile',
		name: 'profile-view',
		component: function () {
			return import(/* webpackChunkName: "profile-view" */ '../views/profile/View.vue')
		}
	},
	{
		path: '/profile/setpass',
		name: 'profile-set-password',
		component: function () {
			return import(/* webpackChunkName: "profile-view" */ '../views/profile/SetPassword.vue')
		}
	},
].map( item => {
	item.meta = { tab: 'profile' }
	return item
})