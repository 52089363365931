<template>
	<div>
		<component :is="layout">
			<router-view />
		</component>
	</div>
</template>

<style lang="scss">
@import 'assets/scss/app';
</style>

<script>
export default {
	computed: {
		layout() {
			// console.log( ( this.$route.meta.layout || "f-default" ) + "-layout" )
			return ( this.$route.meta.layout || "f-default" ) + "-layout"
		}
	},
  watch: {
    '$store.state.auth': {
      handler: function (value) {
        this.$i18n.locale = value.lang;
        this.$vuetify.lang.current = value.lang;
      },
      deep: true
    }
  }
}
</script>
