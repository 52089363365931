import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// Plugins
import globals from './plugins/globals'
import sharedComponents from './plugins/sharedComponents'
import vuetify from './plugins/vuetify'
import inputMask from './plugins/inputMask'
import getPermissions from './plugins/getPermissions'
import AxiosInterceptors from './plugins/axiosInterceptors'
import utils from './plugins/utils'

// Packages Languages
import VueI18n from 'vue-i18n';
import PT_LANG from './i18n/pt';
import EN_LANG from './i18n/en';
import ES_LANG from './i18n/es';

Vue.use(AxiosInterceptors)
Vue.use(sharedComponents)
Vue.use(globals)
Vue.use(inputMask)
Vue.use(getPermissions, {
  store,
  router
})
Vue.use(utils)
Vue.use(VueI18n)
const messages = {
  en: EN_LANG,
  es: ES_LANG,
  pt: PT_LANG,
}

let lang = navigator.language.slice(0, 2)

if (localStorage.getItem("currentLang") != null) {
  lang = localStorage.getItem("currentLang")
}

export const i18n = new VueI18n({
  locale: lang,
  messages,
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App)
}).$mount('#app')
