/**
 * Number.prototype.format(n, x, s, c)
 *
 * @param integer n: length of decimal
 * @param integer x: length of whole part
 * @param mixed   s: sections delimiter
 * @param mixed   c: decimal delimiter
 */
//  Number.prototype.format = function(n, x, s, c) {
//     var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
//         num = this.toFixed(Math.max(0, ~~n));

//     return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
// };

// 12345678.9.format(2, 3, '.', ',');  // "12.345.678,90"
// 123456.789.format(4, 4, ' ', ':');  // "12 3456:7890"
// 12345678.9.format(0, 3, '-');       // "12-345-679"

// function Module(options = {}){
//     let defaults = {
//         color: 'red'
//     };
//     let actual = Object.assign({}, defaults, options);
//     console.info( actual.color );
// }

export default {
	money(value, options = {}) {
		if (typeof value == "string") {
			value = parseFloat(value.replace(",", "."));
		}
		var defaults = {
			decimalLength: 9,
			decimalDelimiter: ",",
			sectionDelimiter: ".",
			prefix: "R$ ",
		};
		options = Object.assign({}, defaults, options);

		var regex =
				"\\d(?=(\\d{3})+" + (options.decimalLength > 0 ? "\\D" : "$") + ")",
			number = value.toFixed(Math.max(0, ~~options.decimalLength));

		return (
			options.prefix +
			number
				.replace(".", options.decimalDelimiter)
				.replace(new RegExp(regex, "g"), "$&" + options.sectionDelimiter)
		);
	},
	clear(value) {
		var clean = value.replace(/[^0-9,]/g, "");
		return clean;
	},
	formatWithCurrency(value, currencyType = null) {
		switch (currencyType) {
			case "USD":
				return new Intl.NumberFormat("en-US", {
					style: "currency",
					currency: "USD",
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
				})
					.format(value)
					.replace("$", "$ ");
			case "REAL":
				return new Intl.NumberFormat("pt-BR", {
					style: "currency",
					currency: "BRL",
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
				})
					.format(value)
					.replace("R$", "R$ ");
			default:
				return value.toString();
		}
	},
	formatCurrencyByPattern(value, currencyPattern) {
		if (value) {
			const formaters = {
				usd: (value) => {
					// 2,000.98 => 2000.98
					return parseFloat(
						value
							.toString()
							.replace(/[^0-9,\.]/gm, "")
							.replace(/,/g, "")
					);
				},
				brl: (value) => {
					// 2.000,98 => 2000.98
					return parseFloat(
						value
							.toString()
							.replace(/[^0-9,\.]/gm, "")
							.replace(/\./g, "")
							.replace(/,/g, ".")
					);
				},
			};
			if (formaters[currencyPattern]) {
				return formaters[currencyPattern](value);
			}
		}

		return 0;
	},
};
